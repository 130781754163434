import React, { useMemo, useCallback } from 'react';
import { createPortal } from 'react-dom';
import pt from 'prop-types';

import {
  removeSwellPagePrefix,
  getParentFocusedField,
} from 'components/storefront/editor/utils/easyblocks';
import { postEasyblocksFocus } from 'components/storefront/editor/utils/post-message';

import BlockContent from './block-content';
import BlockOverlay from './block-overlay';

function Block(props) {
  const {
    Root,
    __easyblocks: easyblocks,
    blockConfigs,
    blockData,
    element,
    locale,
  } = props;
  const { id: easyblocksId, path } = easyblocks;

  const selected = path === getParentFocusedField();
  const id = useMemo(() => easyblocksId.replace('.', ''), [easyblocksId]);
  const normalizedPath = useMemo(() => removeSwellPagePrefix(path), [path]);

  const onClick = useCallback(
    (event) => {
      event.stopPropagation();

      postEasyblocksFocus(normalizedPath, window.parent);
    },
    [normalizedPath],
  );

  const RootComponent = Root.type;

  return createPortal(
    <RootComponent
      {...Root.props}
      id={id}
      className="swell-page-block"
      onClick={onClick}
    >
      <BlockContent
        html={element.html}
        blockConfigs={blockConfigs}
        blockData={blockData}
        path={path}
        locale={locale}
      />
      <BlockOverlay
        path={normalizedPath}
        selected={selected}
        blockConfigs={blockConfigs}
      />
    </RootComponent>,
    element.container,
    path,
  );
}

Block.propTypes = {
  Root: pt.element,
  __easyblocks: pt.object,
  blockConfigs: pt.object,
  blockData: pt.object,
  element: pt.shape({ container: pt.instanceOf(HTMLElement), html: pt.string }),
  locale: pt.string,
};

export default React.memo(Block);
