import React from 'react';
import pt from 'prop-types';
import { scopeCustomCSS } from '@swell/apps-sdk';

function CustomCSS(props) {
  const { id, customCSS } = props;
  const hasCSS = customCSS?.length > 0;

  if (!hasCSS) {
    return null;
  }

  return <style>{scopeCustomCSS(customCSS, id)}</style>;
}

CustomCSS.propTypes = {
  id: pt.string,
  customCSS: pt.string,
};

export default React.memo(CustomCSS);
