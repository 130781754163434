import React, { useState, useEffect, useCallback, useRef } from 'react';
import pt from 'prop-types';
import cx from 'classnames';
import {
  MESSAGE_EVENTS,
  postStorefrontItemHover,
  postStorefrontItemUnhover,
} from 'components/storefront/editor/utils/post-message';
import AddBlockButtons from './add-block-buttons';

import './block-overlay.scss';

function BlockOverlay({ path, selected, blockConfigs }) {
  const [sidebarHovered, setSidebarHovered] = useState(false);
  const [showBlockModal, setShowBlockModal] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    const eventListener = (event) => {
      const { type, payload } = event.data;

      switch (type) {
        case MESSAGE_EVENTS.STOREFRONT_EDITOR.ITEM.HOVER:
          return payload.path === path && setSidebarHovered(true);

        case MESSAGE_EVENTS.STOREFRONT_EDITOR.ITEM.UNHOVER:
          return setSidebarHovered(false);

        default:
          return;
      }
    };

    window.addEventListener('message', eventListener);

    return () => {
      window.removeEventListener('message', eventListener);
    };
  }, [path]);

  const onMouseEnter = useCallback(() => {
    postStorefrontItemHover(path, window.parent);
  }, [path]);

  const onMouseLeave = useCallback(() => {
    if (!showBlockModal) {
      return postStorefrontItemUnhover(path, window.parent);
    }
  }, [path, showBlockModal]);

  const onOpenBlockModal = useCallback(() => {
    setShowBlockModal(true);
  }, []);

  const onCloseBlockModal = useCallback(() => {
    setShowBlockModal(false);
  }, []);

  return (
    <div
      className={cx('block-overlay', {
        selected,
        hovered: sidebarHovered || showBlockModal,
        'with-buttons': showBlockModal,
      })}
      ref={ref}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {!selected && (
        <AddBlockButtons
          path={path}
          blockConfigs={blockConfigs}
          onOpenBlockModal={onOpenBlockModal}
          onCloseBlockModal={onCloseBlockModal}
        />
      )}
    </div>
  );
}

BlockOverlay.propTypes = {
  path: pt.string,
  selected: pt.bool,
  blockConfigs: pt.object,
};

export default React.memo(BlockOverlay);
