export function parsePath(path) {
  const [sectionGroup, sectionIndex, blockGroup, blockIndex] = path.split('.');

  return [sectionGroup, Number(sectionIndex), blockGroup, Number(blockIndex)];
}

function getBlockType(block) {
  const [, , blockType] = block?._component?.split('__') || [];

  return blockType;
}

export function canAddBlock(section, blockConfigs) {
  if (!section) {
    return false;
  }

  const { Blocks: blocks } = section.props.compiled.components;
  const hasBlocks = blocks?.length > 0;

  if (!hasBlocks) {
    return false;
  }

  const blockCountByType = blocks.reduce((acc, block) => {
    const blockType = getBlockType(block);
    const hasBlockConfig = Boolean(blockType && blockConfigs[blockType]);

    if (hasBlockConfig) {
      acc[blockType] = (acc[blockType] || 0) + 1;
    }

    return acc;
  }, {});

  for (const [type, config] of Object.entries(blockConfigs)) {
    const count = blockCountByType[type];

    if (!count) {
      return true;
    }

    const { limit } = config;
    const hasLimit = typeof limit === 'number';

    if (!hasLimit || count < limit) {
      return true;
    }
  }

  return false;
}

export function getTopButtonPath(group, index) {
  return `${group}.${index}`;
}

export function getBottomButtonPath(group, index) {
  return `${group}.${index + 1}`;
}
