import { loadStyle } from 'components/storefront/editor/easyblocks-page/parser/utils';
import { getAttributeValue } from './utils';

function processStyle(assetLoaders) {
  return {
    selector: 'style',
    remove: true,
    processNode: (node) => {
      const hash = getAttributeValue(node, 'data-hash');
      const content = node.innerHTML;

      loadStyle(hash, content, assetLoaders);
    },
  };
}

export default processStyle;
