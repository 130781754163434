import { loadStylesheet } from 'components/storefront/editor/easyblocks-page/parser/utils';
import { isLayoutSectionAsset } from './utils';

function processStylesheet(assetLoaders) {
  return {
    shouldProcessNode: (node) => {
      return (
        node.name === 'link' &&
        node.attribs?.rel === 'stylesheet' &&
        !isLayoutSectionAsset(node)
      );
    },
    processNode: (node) => {
      const { href } = node.attribs;

      loadStylesheet(href, assetLoaders);

      return null;
    },
  };
}

export default processStylesheet;
