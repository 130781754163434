import React from 'react';
import { LayoutContent } from 'components/storefront/editor/easyblocks-page/components';

function processLayoutContent(pageContentString) {
  return {
    shouldProcessNode: function (node) {
      return node.name === 'content-for-layout';
    },
    processNode: function (_node, _children, index) {
      if (pageContentString) {
        return (
          <div
            key={index}
            dangerouslySetInnerHTML={{ __html: pageContentString }}
          />
        );
      }

      return React.createElement(LayoutContent, { key: index });
    },
  };
}

export default processLayoutContent;
