import { Parser as HtmlToReactParser } from 'html-to-react';

const htmlToReactParser = HtmlToReactParser();

const isValidNode = () => true;

function parse(template, instructions) {
  return htmlToReactParser.parseWithInstructions(
    template,
    isValidNode,
    instructions,
  );
}

export default parse;
