import React, { useCallback } from 'react';
import pt from 'prop-types';
import { postStorefrontOverlayAddPageItem } from 'components/storefront/editor/utils/post-message';

function AddSectionPopupButton({ path, label, onClick }) {
  const onOpenSectionModal = useCallback(
    (event) => {
      event.stopPropagation();

      const domRect = event.target.getBoundingClientRect();

      postStorefrontOverlayAddPageItem(domRect, path, window.parent);
      onClick();
    },
    [path, onClick],
  );

  return (
    <button
      className="section-overlay--add-section-popup-button"
      onClick={onOpenSectionModal}
    >
      Add section to {label}
    </button>
  );
}

AddSectionPopupButton.propTypes = {
  path: pt.string,
  label: pt.string,
  onClick: pt.func,
};

export default React.memo(AddSectionPopupButton);
