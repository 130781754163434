import React from 'react';

import { handleForm } from 'components/storefront/editor/easyblocks-page/parser/utils';

import { replaceProps, applyAttributes } from './utils';

/** @typedef {import('domhandler').Element} Element */

function processForm(theme) {
  return {
    /** @param {Element} node */
    shouldProcessNode(node) {
      return node.name === 'form';
    },
    /** @param {Element} node */
    processNode(node, children, index) {
      const { action } = node.attribs;

      applyAttributes(node, handleForm(theme, action));
      replaceProps(node);

      return React.createElement(node.name, node.attribs, ...children);
    },
  };
}

export default processForm;
