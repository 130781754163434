import React from 'react';
import cx from 'classnames';
import { localeCountry } from 'utils/geo-flags';
import './icon.scss';
import './3x2-flags.scss';

export default class Icon extends React.Component {
  getImgPath(type, fallback = undefined) {
    try {
      return require(`./img/${type}@2x.png`);
    } catch (err) {
      if (fallback) {
        return this.getImgPath(fallback);
      }
      return undefined;
    }
  }

  getSvgPath(type, fallback = undefined) {
    try {
      return require(`./svg/${type}.svg`);
    } catch (err) {
      if (fallback) {
        return this.getSvgPath(fallback);
      }
      return undefined;
    }
  }

  renderLocaleIcon(locale) {
    const countryCode = localeCountry(locale);
    return (
      <span aria-hidden="true" className="icon-locale">
        {countryCode ? (
          <span className={`icon-locale-flag icon-3x2-flag:${countryCode}`} />
        ) : (
          '🏳'
        )}
      </span>
    );
  }

  render() {
    const {
      type,
      width,
      height,
      className,
      fa,
      faType,
      faSize = '1x',
      svgType,
      locale,
      fallback,
    } = this.props;

    if (fa) {
      let prefix;
      switch (faType) {
        case 'light':
          prefix = 'fal';
          break;
        case 'solid':
          prefix = 'fas';
          break;
        case 'brand':
          prefix = 'fab';
          break;
        case 'regular':
          prefix = 'far';
          break;
        default:
          if (fa.startsWith('brands')) {
            prefix = '';
          } else {
            prefix = 'far';
          }
      }
      return (
        <span
          aria-hidden="true"
          className={cx(
            'fa-icon',
            `fa-${fa}`,
            `fa-${faSize}`,
            prefix,
            faType && `fa-${faType}`,
            className,
          )}
          style={{ width, height }}
        />
      );
    }

    if (svgType) {
      const svgPath = this.getSvgPath(svgType, fallback);
      if (svgPath === undefined) {
        return null;
      }
      return (
        <img
          alt={svgType}
          className={`icon icon-image icon-${svgType.replace('/', '-')} ${
            className || ''
          }`}
          src={svgPath}
          style={{ width, height }}
        />
      );
    }

    if (locale) {
      return this.renderLocaleIcon(locale);
    }

    const imgPath = this.getImgPath(type, fallback);
    if (imgPath === undefined) {
      return null;
    }

    return (
      <i
        className={`icon icon-${type.replace('/', '-')} ${className || ''}`}
        style={{
          width,
          height,
          backgroundImage: `url(${imgPath})`,
        }}
      />
    );
  }
}
