import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  useRef,
} from 'react';
import pt from 'prop-types';
import cx from 'classnames';
import {
  resolveSectionSettings,
  getSectionSettingsFromProps,
} from '@swell/apps-sdk';
import isEqual from 'lodash/isEqual';

import { useInlineEditable } from 'components/storefront/editor/easyblocks-page/components/inline-editable';
import {
  removeSwellPagePrefix,
  getParentFocusedField,
} from 'components/storefront/editor/utils/easyblocks';
import { postEasyblocksFocus } from 'components/storefront/editor/utils/post-message';

import CustomCSS from './custom-css';
import SectionOverlay from './section-overlay';
import parse from './parser';
import { compileSectionData } from './utils';

const STYLE_LOADING_TIMEOUT = 2000;

function Section(props) {
  const {
    Root,
    Blocks: blocks,
    __easyblocks: easyblocks,
    className,
    sectionSchema,
    sectionGroups,
    custom_css: customCSS,
    theme,
  } = props;
  const { id: easyblocksId, path } = easyblocks;

  const [template, setTemplate] = useState('');
  const [, setElements] = useState([]);
  const [blockElements, setBlockElements] = useState([]);
  const [assetLoaders, setAssetLoaders] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const [mounted, setMounted] = useState(false);
  const { editing } = useInlineEditable();
  const sectionDataRef = useRef(null);
  /** @type {React.MutableRefObject<HTMLDivElement | null>} */
  const contentRef = useRef(null);

  const focusedField = getParentFocusedField();
  const selected = path === focusedField;
  const blockSelected = focusedField !== path && focusedField.includes(path);

  const id = useMemo(() => easyblocksId.replace('.', ''), [easyblocksId]);
  const normalizedPath = useMemo(() => removeSwellPagePrefix(path), [path]);
  const locale = useMemo(() => {
    const { locale } = theme.swell.getStorefrontLocalization();

    return locale;
  }, [theme]);
  const blockConfigs = useMemo(() => {
    return (sectionSchema.blocks || []).reduce((acc, block) => {
      acc[block.type] = block;

      return acc;
    }, {});
  }, [sectionSchema]);

  const sectionData = useMemo(() => {
    const settingProps = getSectionSettingsFromProps(props, sectionSchema);

    const sectionData = resolveSectionSettings(theme, {
      settings: {
        section: {
          ...settingProps,
          id,
        },
      },
      schema: {
        ...sectionSchema,
        id,
      },
    });

    sectionDataRef.current = isEqual(sectionDataRef.current, sectionData)
      ? sectionDataRef.current
      : sectionData;

    return sectionDataRef.current;
  }, [id, props, sectionSchema, theme]);

  useEffect(() => {
    let isMounted = true;

    async function renderThemeTemplate() {
      // Prevent rerender during inline editing
      if (editing) {
        return;
      }

      console.log('render section', sectionSchema.id, sectionData);

      const compiledSectionData = await compileSectionData(theme, sectionData);

      const themeTemplate = await theme.renderThemeTemplate(
        `theme/sections/${sectionSchema.id}.liquid`,
        compiledSectionData,
      );

      if (isMounted) {
        setTemplate(themeTemplate);
      }
    }

    renderThemeTemplate();

    return () => {
      isMounted = false;
    };
  }, [editing, sectionData, sectionSchema, theme, theme.globals]);

  useEffect(() => {
    if (!template) {
      return;
    }

    setElements((prevElements) => {
      const { elements, blockElements, assetLoaders } = parse(
        template,
        theme,
        prevElements,
      );

      setBlockElements(blockElements);
      setAssetLoaders(assetLoaders);

      if (contentRef.current) {
        contentRef.current.replaceChildren(...elements);

        setMounted(true);
      }

      return elements;
    });
  }, [template, theme]);

  useEffect(() => {
    if (loaded || !assetLoaders) {
      return;
    }

    let loadingTimeout = setTimeout(() => {
      setLoaded(true);
      loadingTimeout = 0;
    }, STYLE_LOADING_TIMEOUT);

    // Non-blocking loading of assets
    Promise.allSettled(assetLoaders.map((loader) => loader())).finally(() => {
      if (loadingTimeout) {
        setLoaded(true);
        clearTimeout(loadingTimeout);
        loadingTimeout = 0;
      }
    });

    return () => {
      if (loadingTimeout) {
        clearTimeout(loadingTimeout);
      }
    };
  }, [assetLoaders, loaded]);

  const onClick = useCallback(
    (event) => {
      event.stopPropagation();

      postEasyblocksFocus(normalizedPath, window.parent);
    },
    [normalizedPath],
  );

  const RootComponent = Root.type;

  return (
    <RootComponent
      {...Root.props}
      id={id}
      className={cx('swell-page-section', sectionSchema.class, className)}
      onClick={onClick}
    >
      <div ref={contentRef} className="swell-page-section-content" />
      <CustomCSS id={id} customCSS={customCSS} />
      {mounted && (
        <SectionOverlay
          path={normalizedPath}
          sectionGroups={sectionGroups}
          selected={selected}
          blockSelected={blockSelected}
        />
      )}
      {blockElements.map((blockElement, index) => {
        const Root = blocks[index];
        const blockData = sectionData.section.blocks[index];

        if (!Root || !blockData) {
          return null;
        }

        const RootComponent = Root.type;

        return (
          <RootComponent
            {...Root.props}
            key={index}
            Root={Root}
            element={blockElement}
            blockConfigs={blockConfigs}
            blockData={blockData}
            locale={locale}
          />
        );
      })}
    </RootComponent>
  );
}

Section.propTypes = {
  Root: pt.element,
  Blocks: pt.array,
  __easyblocks: pt.object,
  id: pt.string,
  className: pt.string,
  sectionSchema: pt.object,
  sectionGroups: pt.array,
  custom_css: pt.string,
  theme: pt.object,
};

function getSectionComponent(props, sectionSchema, theme) {
  const { sectionGroups } = props;

  function SectionWrapper(sectionProps) {
    return (
      <Section
        sectionSchema={sectionSchema}
        sectionGroups={sectionGroups}
        theme={theme}
        {...sectionProps}
      />
    );
  }

  return React.memo(SectionWrapper);
}

export default getSectionComponent;
