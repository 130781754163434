import { loadStyle } from 'components/storefront/editor/easyblocks-page/parser/utils';
import { isLayoutSectionAsset } from './utils';

function processStyle(assetLoaders) {
  return {
    shouldProcessNode: (node) => {
      return node.name === 'style' && !isLayoutSectionAsset(node);
    },
    processNode: (node) => {
      const hash = node.attribs?.['data-hash'];
      const content = node.children?.[0]?.data;

      loadStyle(hash, content, assetLoaders);

      return null;
    },
  };
}

export default processStyle;
