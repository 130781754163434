import React, { useMemo, useState, useContext, createContext } from 'react';
import pt from 'prop-types';

export const InlineEditableContext = createContext({
  editing: false,
  /** @type {React.Dispatch<React.SetStateAction<boolean>>} */
  setEditing: () => {},
});

function InlineEditableProviderComponent({ children }) {
  const [editing, setEditing] = useState(false);

  const value = useMemo(() => {
    return { editing, setEditing };
  }, [editing]);

  return (
    <InlineEditableContext.Provider value={value}>
      {children}
    </InlineEditableContext.Provider>
  );
}

InlineEditableProviderComponent.propTypes = {
  children: pt.oneOfType([pt.element, pt.array]),
};

export const InlineEditableProvider = React.memo(
  InlineEditableProviderComponent,
);

export const useInlineEditable = () => {
  const context = useContext(InlineEditableContext);

  if (!context) {
    throw new Error(
      'useInlineEditable must be used within an InlineEditableProvider',
    );
  }

  return context;
};
