import React, { useContext } from 'react';
import pt from 'prop-types';

import { RootContext } from 'components/storefront/editor/easyblocks-page/components/root';

import {
  isLastSection,
  isLastFooterSection,
  getBottomButtonPath,
  getBottomButtonPopupLabels,
  getBottomButtonPopupPaths,
} from './utils';
import AddSectionButton from './button';

function BottomAddSectionButton({
  group,
  index,
  sectionGroups,
  onOpenSectionModal,
  onCloseSectionModal,
}) {
  const rootContext = useContext(RootContext);

  const groupSections = rootContext[group];
  const sectionsLength = groupSections?.length;

  if (isLastFooterSection(group, sectionGroups, index, sectionsLength)) {
    return null;
  }

  const popupLabels = getBottomButtonPopupLabels(group, sectionGroups);
  const popupPaths = getBottomButtonPopupPaths(group, sectionGroups, index);

  if (!popupLabels || !popupPaths) {
    return null;
  }

  return (
    <AddSectionButton
      className="section-overlay--add-section-button-bottom"
      popup={isLastSection(index, sectionsLength)}
      popupLabels={popupLabels}
      popupPaths={popupPaths}
      path={getBottomButtonPath(group, index)}
      onOpenSectionModal={onOpenSectionModal}
      onCloseSectionModal={onCloseSectionModal}
    />
  );
}

BottomAddSectionButton.propTypes = {
  group: pt.string,
  index: pt.number,
  sectionGroups: pt.array,
  sectionsCount: pt.number,
  onOpenSectionModal: pt.func,
  onCloseSectionModal: pt.func,
};

export default React.memo(BottomAddSectionButton);
