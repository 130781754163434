import React from 'react';
import pt from 'prop-types';

function Global(props) {
  const { Root, swell_page: SwellPage, ...data } = props;
  const RootComponent = Root.type;
  const SwellPageComponent = SwellPage.type;

  return (
    <RootComponent {...Root.props}>
      <SwellPageComponent {...SwellPage.props} {...data} />
    </RootComponent>
  );
}

Global.propTypes = {
  Root: pt.element,
  swell_page: pt.element,
};

export default React.memo(Global);
