import React, { useState, useEffect, useMemo, useRef } from 'react';
import pt from 'prop-types';
import {
  resolveThemeSettings,
  getThemeSettingsFromProps,
} from '@swell/apps-sdk';
import isEqual from 'lodash/isEqual';

import { InlineEditableProvider } from 'components/storefront/editor/easyblocks-page/components/inline-editable';
import { postStorefrontCanvasLoaded } from 'components/storefront/editor/utils/post-message';

import { RootContext } from './index';
import parse from './parser';

const STYLE_LOADING_TIMEOUT = 2000;

function Root(props) {
  const { Root, pageProps, layoutProps, pageContent, editorSchema, theme } =
    props;

  const [template, setTemplate] = useState('');
  const [pageContentString, setPageContentString] = useState(null);
  const [Content, setContent] = useState(() => <div />);
  const [assetLoaders, setAssetLoaders] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const settingsRef = useRef(null);

  const settings = useMemo(() => {
    const settingProps = getThemeSettingsFromProps(props, editorSchema);
    const settings = resolveThemeSettings(theme, settingProps, editorSchema);

    settingsRef.current = isEqual(settingsRef.current, settings)
      ? settingsRef.current
      : settings;

    return settingsRef.current;
  }, [props, editorSchema, theme]);

  useEffect(() => {
    console.log('render layout', { settings });
    theme.setGlobals({ settings });

    theme
      .renderLayout({
        ...layoutProps,
        content_for_header: theme.getContentForHeader(),
        content_for_layout: '<content-for-layout></content-for-layout>',
      })
      .then(async (template) => {
        // Render theme page directly when it's a raw liquid file
        const pageContentString =
          typeof pageContent === 'string'
            ? await theme.renderTemplateString(pageContent, pageProps)
            : null;

        setTemplate(template);
        setPageContentString(pageContentString);
      });
  }, [settings, layoutProps, pageContent, pageProps, theme, theme.globals]);

  useEffect(() => {
    if (!template) {
      return;
    }

    const { Content, assetLoaders } = parse(theme, template, pageContentString);

    setContent(Content);
    setAssetLoaders(assetLoaders);
  }, [template, pageContentString, theme]);

  useEffect(() => {
    if (loaded || !assetLoaders) {
      return;
    }

    let loadingTimeout = setTimeout(() => {
      setLoaded(true);
      loadingTimeout = 0;
    }, STYLE_LOADING_TIMEOUT);

    // Non-blocking load assets
    Promise.allSettled(assetLoaders.map((loader) => loader())).finally(() => {
      if (loadingTimeout) {
        setLoaded(true);
        clearTimeout(loadingTimeout);
        loadingTimeout = 0;
      }
    });

    return () => {
      if (loadingTimeout) {
        clearTimeout(loadingTimeout);
      }
    };
  }, [assetLoaders, loaded]);

  useEffect(() => {
    if (loaded) {
      postStorefrontCanvasLoaded(window.parent);
    }
  }, [loaded]);

  const RootComponent = Root.type;

  return (
    <RootComponent {...Root.props} className="easyblocks-page--root">
      <RootContext.Provider value={props}>
        <InlineEditableProvider>{Content}</InlineEditableProvider>
      </RootContext.Provider>
    </RootComponent>
  );
}

Root.propTypes = {
  Root: pt.element,
  theme: pt.object,
  pageProps: pt.object,
  layoutProps: pt.object,
  pageContent: pt.oneOfType([pt.string, pt.object]),
  editorSchema: pt.array,
};

function getRootComponent(props, theme) {
  const { layoutProps, pageProps, pageContent } = props;
  const editorSchema = pageProps.configs?.editor?.settings;

  function RootWrapper(rootProps) {
    return (
      <Root
        layoutProps={layoutProps}
        pageProps={pageProps}
        pageContent={pageContent}
        editorSchema={editorSchema}
        theme={theme}
        {...rootProps}
      />
    );
  }

  return React.memo(RootWrapper);
}

export default getRootComponent;
