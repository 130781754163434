import React from 'react';
import qs from 'qs';
import moment from 'moment-timezone';
import Cookies from 'universal-cookie';
import {
  get,
  map,
  find,
  each,
  reduce,
  isEqual,
  cloneDeep,
  isEmpty as _isEmpty,
  template as lodashTemplate,
  templateSettings,
} from 'lodash';
import currencies from 'currency-formatter/currencies.json';
import currencyFormatter from 'currency-formatter';
import NumAbbrev from 'number-abbreviate';
import inflection from 'inflection';
import { default as baseNormalizeUrl } from 'normalize-url';
import timeAgo from 's-ago';
import classNames from 'classnames';
import { stringifyQuery } from 'shared/utils';
export { classNames };

export * from '/shared/utils';

const cookies = new Cookies();
const numAbbrev = new NumAbbrev(['K', 'M', 'B', 'T']);

const { BASE_URI, NODE_ENV } = process.env;

const LOADING_SCRIPTS = {};

const MINUTE = 1000 * 60; // 1 minute in ms
const DAY = MINUTE * 60 * 24; // 1 day in ms

// Set timezone globally
export let TIMEZONE = 'America/Los_Angeles';
export function setTimezone(tz) {
  TIMEZONE = tz || TIMEZONE;
}

// Set test environment globally
export let TEST_ENV = '';
export function setTestEnv(id) {
  TEST_ENV = id || '';
}

// Shortcut url segment for other components that need it
export function testUrlSegment(testEnv) {
  if (!testEnv) {
    return '';
  }
  return `${testEnv === 'test' ? 'test/' : `test-${testEnv}/`}`;
}

// Set locale globally
export let LOCALE_CODE = 'en-US';
export function setLocale(code) {
  LOCALE_CODE = String(code || LOCALE_CODE || 'en-US').replace('_', '-');
}

// Set configured locales and codes globally
export const LOCALES = [];
export const LOCALE_CODES = [];

export function setLocaleCodes(locales, codes) {
  if (locales) {
    LOCALES.length = 0;
    LOCALES.push(...locales);
  }

  if (codes) {
    LOCALE_CODES.length = 0;
    LOCALE_CODES.push(...codes);
  }
}

// Set currency globally
export let CURRENCY_CODE = 'USD';
export const CURRENCY_DECIMALS = {};

export function setCurrency(code, decimals = undefined) {
  CURRENCY_CODE = code || 'USD';

  if (decimals !== undefined) {
    CURRENCY_DECIMALS[CURRENCY_CODE] = decimals;
  } else if (CURRENCY_DECIMALS[CURRENCY_CODE] !== undefined) {
    CURRENCY_DECIMALS[CURRENCY_CODE] = undefined;
  }
}

// Set configured currencies and codes globally
export const CURRENCIES = [];
export const CURRENCY_CODES = [];

export function setCurrencyCodes(currencies, codes) {
  if (currencies) {
    CURRENCIES.length = 0;
    CURRENCIES.push(...currencies);
  }

  if (codes) {
    CURRENCY_CODES.length = 0;
    CURRENCY_CODES.push(...codes);
  }

  for (const curr of CURRENCIES) {
    if (curr.decimals !== undefined) {
      CURRENCY_DECIMALS[curr.code] = curr.decimals;
    }
  }
}

// Format a date value with enumerated formats
export function formatDate(value, format, isTimezoneDate = undefined) {
  if (!value && Number.isNaN(new Date(value).getTime())) {
    return null;
  }

  const now = new Date();
  const dateValue = isTimezoneDate
    ? moment.utc(value)
    : moment.utc(value).tz(TIMEZONE);
  const dateDate = dateValue.toDate();

  let dateFormat;

  switch (format) {
    case 'short':
    case 'shortDate':
      dateFormat = 'MMM D';
      if (dateValue.year() !== now.getFullYear()) {
        dateFormat = 'MMM D, YYYY';
      }
      break;
    case 'shortExact':
      dateFormat = 'MMM D, h:mm a';
      if (dateValue.year() !== now.getFullYear()) {
        dateFormat = 'MMM D, YYYY, h:mm a';
      }
      break;
    case 'shortExacter':
      dateFormat = 'MMMM D, h:mm a';
      if (dateValue.year() !== now.getFullYear()) {
        dateFormat = 'MMMM D YYYY, h:mm a';
      }
      break;
    case 'monthDayShort':
      dateFormat = 'MMM D YYYY';

      break;
    case 'monthDayShortComma':
      dateFormat = 'MMM D, YYYY';

      break;
    case 'monthDay':
      dateFormat = 'MMMM D';
      if (dateValue.year() !== now.getFullYear()) {
        dateFormat = 'MMMM D, YYYY';
      }
      break;
    case 'hour':
      dateFormat = 'MMM D, YYYY, h:mm a';
      break;
    case 'year':
      dateFormat = 'YYYY';
      break;
    case 'month':
      dateFormat = 'MMM YYYY';
      break;
    case 'week':
      dateFormat = 'Wo YYYY';
      break;
    case 'time':
      dateFormat = 'h:mm a';
      break;
    case 'long':
    case 'longDate':
      dateFormat = 'LL';
      break;
    case 'longExact':
      dateFormat = 'LL \\a\\t h:mm a';
      break;
    case 'isoDate':
      dateFormat = 'YYYY-MM-DD';
      break;
    case 'isoString':
      return dateValue.toISOString();
    case 'log':
      dateFormat = 'MMM DD HH:mm:ss.SSS';
      break;
    case 'age':
      if (
        now - dateDate > DAY ||
        now - dateDate < 0 ||
        now.getDate() !== dateDate.getDate()
      ) {
        return formatDate(dateDate, 'short');
      }
      dateFormat = 'h:mm a';
      break;
    case 'ago':
      if (now - dateDate < MINUTE) {
        return 'just now';
      }
      if (
        now - dateDate > DAY ||
        now - dateDate < 0 ||
        now.getDate() !== dateDate.getDate()
      ) {
        return formatDate(dateDate, 'short');
      }
      return timeAgo(dateDate);
    case 'recentAgo':
      if (now - dateDate < MINUTE) {
        return 'just now';
      }
      if (now - dateDate > DAY) {
        return formatDate(dateDate, 'short');
      }
      return timeAgo(dateDate);
    default:
      dateFormat = format;
  }

  return dateValue.format(dateFormat);
}

/**
 * Combine date and time into a single ISO datetime
 */
export function combineDateTime(date, time) {
  let dateValue = moment(date).tz(TIMEZONE);

  if (time) {
    const timeValue = moment(time).tz(TIMEZONE);

    return (
      timeValue
        .clone()
        .set({
          year: dateValue.year(),
          month: dateValue.month(),
          date: dateValue.date(),
          hours: timeValue.hours(),
          minutes: timeValue.minutes(),
          seconds: timeValue.seconds(),
          milliseconds: timeValue.milliseconds(),
        })
        // add the difference between time zones (winter and summer time)
        .add(dateValue.utcOffset() - timeValue.utcOffset(), 'minutes')
        .toDate()
    );
  }

  dateValue.set({
    hours: 0,
    minutes: 0,
    seconds: 0,
    milliseconds: 0,
  });

  return dateValue.toDate();
}

/**
 * @param {number} value
 * @param {string?} code
 * @param {currencyFormatter.FormatOptions?} options
 * @returns
 */
export function formatCurrency(value, code = 'USD', options = {}) {
  return `${
    CURRENCY_CODES.length > 0 && CURRENCY_CODE !== code ? `${code} ` : ''
  }${currencyFormatter.format(value, {
    code,
    precision: CURRENCY_DECIMALS[code],
    ...options,
  })}`;
}

/**
 * Format currency with standard decimal places (or no decimal places if the decimal part is zeros)
 *
 * @param {number | string} value
 * @param {string?} code
 * @param {currencyFormatter.FormatOptions?} options
 * @returns {string}
 */
export function formatCurrencyMaybeRound(value, code = 'USD', options = {}) {
  value = Number(value);

  return currencyFormatter.format(value, {
    code,
    ...options,
    precision: Number.isInteger(value) ? 0 : undefined,
  });
}

/**
 * Format currency with standard decimal places
 *
 * @param {number | string} value
 * @param {string?} code
 * @param {currencyFormatter.FormatOptions?} options
 * @returns {string}
 */
export function formatCurrencyRounded(
  value,
  code = 'USD',
  options = undefined,
) {
  return currencyFormatter.format(Number(value), {
    code,
    ...options,
    precision: undefined,
  });
}

/**
 * Get currency symbol with ISO code
 *
 * @param {string} code
 * @returns {string}
 */
export function currencySymbol(code) {
  return currencies[code] ? currencies[code].symbol : '?';
}

export function numberLocaleInfo(locale = LOCALE_CODE) {
  try {
    const numberWithGroupAndDecimalSeparator = 1000.1;
    const parts = Intl.NumberFormat(locale).formatToParts(
      numberWithGroupAndDecimalSeparator,
    );
    return {
      decimal: get(
        parts.find((part) => part.type === 'decimal'),
        'value',
        '.',
      ),
      group: get(
        parts.find((part) => part.type === 'group'),
        'value',
        ',',
      ),
    };
  } catch (err) {
    // Fallback to currency
    try {
      const currencyInfo = currencyFormatter.findCurrency(CURRENCY_CODE);
      return {
        decimal: currencyInfo.decimalSeparator,
        group: currencyInfo.thousandsSeparator,
      };
    } catch (err) {
      // noop
    }
  }
  // Fallback to en-US
  return { decimal: '.', group: ',' };
}

/**
 * Get currency float value with decimals for input
 *
 * @param {number | string} value
 * @param {string?} code
 * @param {currencyFormatter.FormatOptions?} options
 * @returns {string}
 */
export function currencyValue(value, code = 'USD', options = {}) {
  const floatVal =
    typeof value === 'string' ? parseCurrency(value, code) : value;

  return !currencies[code] || Number.isNaN(floatVal)
    ? value.toString()
    : currencyFormatter.format(floatVal, {
        code,
        symbol: options.isSymbol ? undefined : '',
        format: options.isSymbol ? undefined : '%v',
        ...options,
        precision:
          'precision' in options ? options.precision : CURRENCY_DECIMALS[code],
      });
}

export function formatNumber(value, locale = LOCALE_CODE) {
  let num = '';

  try {
    num = Number(value).toLocaleString(locale);
  } catch (error) {
    // in case locale is an invalid locale code, use the default code
    if (error instanceof RangeError) {
      num = Number(value).toLocaleString(LOCALE_CODE);
    }
  }

  return num === 'NaN' ? value : num;
}

export function formatNumberAbbreviated(value = 0) {
  return numAbbrev.abbreviate(value, 2);
}

export function parseNumber(value, locale = LOCALE_CODE) {
  return currencyFormatter.unformat(value, {
    locale,
  });
}

/**
 * @param {string} value
 * @param {string?} code
 * @returns {number}
 */
export function parseCurrency(value, code = CURRENCY_CODE) {
  return currencyFormatter.unformat(value, {
    code,
  });
}

export function toFixed(value, n) {
  try {
    return value.toFixed(n);
  } catch (e) {
    return value;
  }
}

export const BLANK_IMAGE_URL =
  'data:image/gif;base64,R0lGODlhAQABAAAAACwAAAAAAQABAAA=';

// Get an image CDN url with params and default
export function imageUrl(record, params) {
  let url;
  if (record) {
    if (record.images) {
      // Product record
      url = get(record, 'images.0.file.url');
    } else if (record.file) {
      // Image record
      url = record.file.url;
    } else if (record.url) {
      // File record
      url = record.url;
    } else if (typeof record === 'string') {
      url = record;
    }
  }
  if (url) {
    if (!params.padded) {
      delete params.padded;
    }
    url = `${url}${stringifyQuery(params)}`;
  } else {
    // Blank image
    url = params.blank !== undefined ? params.blank : BLANK_IMAGE_URL;
  }
  return url;
}

templateSettings.interpolate = /{([\s\S]+?)}/g;

// Parse and execute a template string with values
export function template(str, values) {
  try {
    return lodashTemplate(str)(values);
  } catch (err) {
    return '';
  }
}

/**
 * Convert an array to object with id field from key
 *
 * @template T
 * @param {Array<T>} arr
 * @param {string} [key='id']
 * @returns {Record<string, T>}
 */
export function arrayToObject(arr, key = 'id') {
  return reduce(
    arr,
    (obj, value) => {
      obj[value[key]] = value;
      return obj;
    },
    {},
  );
}

/**
 * Convert an array to map with id field from key
 *
 * @template T
 * @param {Array<T>} arr
 * @param {string} [key='id']
 * @returns {Map<string, T>}
 */
export function arrayToMap(arr, key = 'id') {
  return reduce(
    arr,
    (map, value) => {
      return map.set(value[key], value);
    },
    new Map(),
  );
}

/**
 * Convert an object to array with id field from key
 *
 * @template T
 * @param {Record<string, T>} obj
 * @param {string} [key='id']
 * @returns {Array<T>}
 */
export function objectToArray(obj, key = 'id') {
  if (Array.isArray(obj)) {
    return obj;
  }

  return map(obj, (value, id) => ({ ...value, [key]: id }));
}

/**
 * Convert a query string to object
 *
 * @param {string} str
 * @returns {object}
 */
export function parseQuery(str) {
  const result = qs.parse(str.replace(/^\?/, ''));
  return result;
}

/**
 * @param {object} query
 * @param {object} param
 * @param {boolean} replaceKeys
 * @returns {object}
 */
export function updateQueryObject(query, param, replaceKeys = false) {
  // Prevent duplicate nested keys, e.g. `sort[year]: -1` and `sort: { year: -1 }`
  const newQuery = cloneDeep(query);

  for (const [key, val] of Object.entries(param)) {
    if (!replaceKeys && isObject(val)) {
      for (const [key2, value] of Object.entries(val)) {
        if (value !== undefined && (value === 0 || String(value).length > 0)) {
          newQuery[key] = newQuery[key] || {};
          newQuery[key][key2] = value;
        } else if (newQuery[key] !== undefined) {
          delete newQuery[key][key2];
        }
      }
    } else if (val !== undefined && (val === 0 || String(val).length > 0)) {
      newQuery[key] = val;
    } else {
      delete newQuery[key];
    }
  }

  return newQuery;
}

/**
 * Get current location with query string appended
 *
 * @param {Location} location
 * @param {object} param
 * @param {boolean} replaceKeys
 * @returns {string}
 */
export function locationWithQuery(location, param, replaceKeys = false) {
  const exQuery = parseQuery(stringifyQuery(location.query));
  const newQuery = updateQueryObject(exQuery, param, replaceKeys);

  return `${location.pathname}${stringifyQuery(newQuery)}`;
}

// Get current tab name from location
export function getTabFromLocation(location) {
  return get(location, 'query.tab');
}

/**
 * Get file data from a file upload
 *
 * @param {File} file
 * @returns {{ data: ArrayBuffer, type: string }}
 */
export function getDataFromFile(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onerror = () => {
      reject(new Error(`Error occurred reading file: ${file.name}`));
    };

    reader.onload = (event) => {
      const fileData = event.target.result;

      resolve({
        data: fileData,
        type: file.type,
      });
    };

    reader.readAsDataURL(file);
  });
}

/**
 * @param {File} file
 * @returns {{ data: ArrayBuffer, type: string, filename: string }}
 */
export function getFileFromFile(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.fileName = file.name;

    reader.onerror = () => {
      reject(new Error(`Error occurred reading file: ${file.name}`));
    };

    reader.onload = (event) => {
      const fileData = event.target.result;
      const filename = event.target.fileName;

      resolve({
        data: fileData,
        type: file.type,
        filename,
      });
    };

    reader.readAsDataURL(file);
  });
}

/**
 * Get image data from a file upload
 *
 * @param {File} file
 * @returns {{ data: ArrayBuffer, type: string, width: number, height: number }}
 */
export function getImageFromFile(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onerror = () => {
      reject(new Error(`Error occurred reading file: ${file.name}`));
    };

    reader.onload = (event) => {
      const fileData = event.target.result;
      // Get dimensions with Image
      const tempImage = new Image();

      tempImage.onerror = (message, _source, _lineno, _colno, error) => {
        reject(error || new Error(message));
      };

      tempImage.onload = () => {
        resolve({
          data: fileData,
          type: file.type,
          width: tempImage.width,
          height: tempImage.height,
          filename: file.name,
        });
      };

      tempImage.src = fileData;
    };

    reader.readAsDataURL(file);
  });
}

/**
 * Validate the max file size of an array of files
 * @param {File[]} files
 * @param {function} onError
 * @returns {boolean}
 */
export function validateMaxFileSize(files, maxSizeMb, onError) {
  let peakFileSize = 0;
  each(files, (file) => {
    if (file.size > peakFileSize) {
      peakFileSize = file.size;
    }
  });

  if (peakFileSize / 1048576 >= maxSizeMb) {
    onError(maxSizeMb);
    return false;
  }

  return true;
}

/**
 * Inflect singular or plural
 */
export function inflect(count = 0, word = '', options = {}) {
  return `${
    options.showCount === false ? '' : `${formatNumber(count)} `
  }${inflection.inflect(word, parseInt(count, 10))}`;
}

/**
 * Singular
 *
 * @param {string} word
 * @returns {string}
 */
export function singularize(word = '') {
  if (typeof word !== 'string') {
    // Sometimes we pass a react component as a label
    if (word?.props?.children?.join) {
      const componentWord = word.props.children.join('').trim();
      return inflection.singularize(componentWord);
    }
    return '';
  }
  return inflection.singularize(word);
}

/**
 * Plural
 *
 * @param {string} word
 * @returns {string}
 */
export function pluralize(word = '') {
  if (typeof word !== 'string') {
    // Sometimes we pass a react component as a label
    if (word?.props?.children?.join) {
      const componentWord = word.props.children.join('').trim();
      return inflection.pluralize(componentWord);
    }
    return '';
  }
  return inflection.pluralize(word);
}

// Split a text input to multiple lines with BR tags
export function nl2br(text = '') {
  if (typeof text !== 'string' || !text.trim()) {
    return null;
  }
  return text.split('\n').map((item, key) => {
    return (
      <span key={key}>
        {item}
        <br />
      </span>
    );
  });
}

// Get a humanized description of the time for greetings
export function timeGreeting() {
  let greet;

  const now = moment();
  const hourAfternoon = 12;
  const hourEvening = 17;
  const hourCurrent = parseFloat(now.format('HH'));

  if (hourCurrent >= hourAfternoon && hourCurrent <= hourEvening) {
    greet = 'afternoon';
  } else if (hourCurrent >= hourEvening) {
    greet = 'evening';
  } else {
    greet = 'morning';
  }

  return greet;
}

export function isSameDay(first, second) {
  return moment(first).isSame(second, 'day');
}

export function isSameTime(first, second) {
  return moment(first).isSame(second, 'minute');
}

export function getCookie(name) {
  return cookies.get(name);
}

export function setCookie(name, value, options = {}) {
  cookies.set(name, JSON.stringify(value), {
    httpOnly: false,
    sameSite: 'lax',
    secure: NODE_ENV !== 'development',
    path: BASE_URI,
    ...options,
  });
}

export function isEmpty(value) {
  switch (typeof value) {
    case 'boolean':
    case 'number':
      return false;

    case 'string':
      return value.trim().length === 0;

    default: {
      if (value instanceof Date) {
        return false;
      }

      return _isEmpty(value);
    }
  }
}

export function isValueEqual(a, b) {
  if (isSingleValueEqual(a, b)) {
    if (a instanceof Date && b instanceof Date) {
      return a.valueOf() === b.valueOf();
    }

    return true;
  }

  try {
    if (a && b && typeof a === 'object') {
      const compare1 = compareValues(a, b);

      if (
        compare1.different.length === 0 &&
        compare1.missing_from_second.length === 0
      ) {
        const compare2 = compareValues(b, a);

        if (
          compare2.different.length === 0 &&
          compare2.missing_from_second.length === 0
        ) {
          return true;
        }
      }
    }
  } catch (err) {
    console.error(err);
  }

  return false;
}

export function isSingleValueEqual(a, b) {
  // eslint-disable-next-line eqeqeq
  if (a == b) {
    return true;
  }
  if (
    ((a === null || a === undefined) && b === '') ||
    (a === '' && (b === null || b === undefined))
  ) {
    return true;
  }
  if ((a === true && b === 'true') || (b === true && a === 'true')) {
    return true;
  }
  if (
    (a === false && (b === 'false' || !b)) ||
    (b === false && (a === 'false' || !a))
  ) {
    return true;
  }
  // TODO: make sure this isn't needed after fixing number null vs 0 check in form component
  // if ((a === 0 && !b) || (b === 0 && !a)) {
  //   return true;
  // }
  if (
    (typeof a === 'object' || typeof b === 'object') &&
    isEmpty(a) &&
    isEmpty(b)
  ) {
    return true;
  }
  return isEqual(a, b);
}

export function compareValues(a, b) {
  const result = {
    different: [],
    //missing_from_first: [],
    missing_from_second: [],
  };
  for (const key in a) {
    const value = a[key];
    // Hack to fix recursive loop on category tree
    if (key === 'parent') {
      continue;
    }
    if (Object.prototype.hasOwnProperty.call(b, key)) {
      let isItEqual;
      try {
        isItEqual = isSingleValueEqual(value, b[key]);
      } catch (err) {
        console.log(value, b[key]);
      }
      if (isItEqual) {
        continue;
      } else {
        if (
          !a[key] ||
          !b[key] ||
          typeof a[key] !== 'object' ||
          typeof b[key] !== 'object'
        ) {
          // dead end.
          if (!isValueEqual(value, b[key])) {
            result.different.push(key);
          }
          continue;
        } else {
          const deeper = compareValues(a[key], b[key]);
          result.different = result.different.concat(
            map(deeper.different, (sub_path) => {
              return key + '.' + sub_path;
            }),
          );

          result.missing_from_second = result.missing_from_second.concat(
            map(deeper.missing_from_second, (sub_path) => {
              return key + '.' + sub_path;
            }),
          );

          // result.missing_from_first = result.missing_from_first.concat(
          //   map(deeper.missing_from_first, (sub_path) => {
          //     return key + '.' + sub_path;
          //   }),
          // );
        }
      }
    } else {
      result.missing_from_second.push(key);
    }
  }

  return result;
}

export function isObject(obj) {
  return typeof obj === 'object' && obj !== null && !(obj instanceof Array);
}

export function toArray(value) {
  if (value instanceof Array) {
    return value;
  }
  const arr = [];
  if (isObject(value)) {
    const keys = Object.keys(value);
    for (let i = 0; i < keys.length; i++) {
      arr.push(value[keys[i]]);
    }
  } else {
    arr.push(value);
  }
  return arr;
}

export function evalConditions(conditions, valueOrig, appRoot = undefined) {
  let match = true;
  const value = valueOrig === undefined ? null : valueOrig;
  if (isObject(conditions)) {
    const keys = Object.keys(conditions);
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
      let compare = conditions[key];
      if (key && key[0] === '$' && !key.includes('.')) {
        switch (key) {
          case '$eq':
            match = value === compare;
            break;
          case '$ne':
            match = value !== compare;
            break;
          case '$lt':
            match = value < compare;
            break;
          case '$lte':
            match = value <= compare;
            break;
          case '$gt':
            match = value > compare;
            break;
          case '$gte':
            match = value >= compare;
            break;
          case '$in':
            match = false;
            compare = toArray(compare);
            if (compare.indexOf(valueOrig) >= 0) {
              match = true;
            }
            break;
          case '$nin':
            match = true;
            compare = toArray(compare);
            if (compare.indexOf(valueOrig) >= 0) {
              match = false;
            }
            break;
          case '$exists':
            match = compare ? valueOrig !== undefined : valueOrig === undefined;
            break;
          case '$empty':
            match = compare ? !value : value;
            break;
          case '$or':
            match = false;
            compare = toArray(compare);
            for (let j = 0; j < compare.length; j++) {
              match = evalConditions(compare[j], value);
              if (match) {
                match = true;
                break;
              }
            }
            break;
          case '$and':
            compare = toArray(compare);
            match = true;
            for (let j = 0; j < compare.length; j++) {
              match = evalConditions(compare[j], value);
              if (!match) {
                match = false;
                break;
              }
            }
            break;
          default:
            // Handle special conditions like $data, $record, $method
            if (value && typeof value === 'object' && key in value) {
              match = evalConditions(compare, value[key]);
            } else {
              match = false;
            }
        }
      } else {
        let thisValue = value;
        if (key.indexOf('.') !== -1) {
          const parts = key.split('.');
          for (let k = 0; k < parts.length; k++) {
            const part = parts[k];
            if (thisValue) {
              thisValue = thisValue[part];
            } else {
              thisValue = null;
              break;
            }
          }
        } else {
          thisValue = thisValue ? thisValue[key] : null;
        }
        match = evalConditions(compare, thisValue);
      }
      if (!match) {
        break;
      }
    }
  } else {
    if (typeof conditions === 'boolean') {
      match = conditions === !!value;
    } else {
      match = conditions === value;
    }
  }
  return match;
}

export function evalConditionsWithAppFields(
  client,
  rootPath,
  conditions,
  values,
) {
  const { appsById, appsBySlug } = client || {};

  // App fields can target own fields and root fields
  const appConditions = reduce(
    conditions,
    (acc, value, key) => {
      const isAppField = rootPath.startsWith('$app');
      const isAppKey = key?.startsWith('$app');
      const isSettingsKey =
        key?.startsWith('$settings.') || key === '$settings';
      const isRootKey = !isSettingsKey && !isAppKey && key?.startsWith('$');

      if (isRootKey) {
        // Apps can target root fields with $ prefix
        acc[key.slice(1)] = value;
      } else if (isSettingsKey) {
        // Settings keys are global
        // Might be other global keys in the future like $user or $store
        acc[key] = value;
      } else if (isAppField && !isAppKey) {
        // Apps can target local fields without prefix
        const [_, appId] = rootPath.split('.');
        acc[`$app.${appId}.${key}`] = value;
      } else if (isAppKey) {
        // Apps can target other apps by id or slug_id
        const [_, appId, appKey] = key.split('.');
        const rootKey = appKey.join('.');
        const $or = (acc['$or'] = acc['$or'] || []);
        appsById?.[appId] &&
          $or.push({ [`$app.${appsById[appId].slug_id}.${rootKey}`]: value });
        appsBySlug?.[appId] &&
          $or.push({ [`$app.${appsBySlug[appId].id}.${rootKey}`]: value });
      } else {
        // Regular fields
        acc[key] = value;
      }
      return acc;
    },
    {},
  );

  return evalConditions(appConditions, values);
}

export function firstError(errors) {
  if (errors && typeof errors === 'object') {
    const keys = Object.keys(errors);
    return get(errors[keys[0]], 'message');
  }
  return undefined;
}

export function loadScript(id, src) {
  LOADING_SCRIPTS[id] =
    LOADING_SCRIPTS[id] ||
    new Promise((resolve) => {
      const script = document.createElement('script');
      script.id = id;
      script.src = src;
      script.async = true;
      script.type = 'text/javascript';
      script.addEventListener(
        'load',
        () => {
          resolve();
          LOADING_SCRIPTS[id] = null;
        },
        {
          once: true,
        },
      );
      document.head.appendChild(script);
    });
  return LOADING_SCRIPTS[id];
}

export function isBase64(str) {
  if (str === '' || str.trim() === '') {
    return false;
  }
  try {
    return btoa(atob(str)) === str;
  } catch (err) {
    return false;
  }
}

export function b64toBlob(b64Data, contentType = '', sliceSize = 512) {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  return new Blob(byteArrays, { type: contentType });
}

export function escapeRegExp(string) {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
}

export function localeFallbackValue(params = {}) {
  const {
    context = {},
    name,
    locale,
    localeConfigs,
    origLocale = undefined,
  } = params;
  const localeConfig =
    typeof locale === 'string' ? find(localeConfigs, { code: locale }) : locale;
  const localeValue = get(
    context.$locale,
    `${localeConfig ? localeConfig.code : LOCALE_CODE}.${name}`,
  );
  if (localeValue && localeValue.length > 0) {
    return localeValue;
  }
  if (
    !localeConfig ||
    !localeConfig.fallback ||
    localeConfig.fallback === LOCALE_CODE
  ) {
    return get(context, name);
  }
  if (localeConfig.fallback === 'none') {
    return localeValue;
  }
  const fallbackValue = get(
    context.$locale,
    `${localeConfig.fallback || LOCALE_CODE}.${name}`,
  );
  if (fallbackValue && fallbackValue.length > 0) {
    return fallbackValue;
  }
  const fallbackLocale = find(localeConfigs, { code: localeConfig.fallback });
  if (fallbackLocale && fallbackLocale !== origLocale) {
    return localeFallbackValue({
      ...params,
      locale: fallbackLocale,
      origLocale: origLocale || localeConfig,
    });
  }
}

export function doesLocaleFallback(params = {}) {
  const {
    context = {},
    name,
    locale,
    localeConfigs,
    origLocale = undefined,
  } = params;
  const localeConfig =
    typeof locale === 'string' ? find(localeConfigs, { code: locale }) : locale;
  const localeValue = get(
    context.$locale,
    `${localeConfig ? localeConfig.code : LOCALE_CODE}.${name}`,
  );
  if (localeValue && localeValue.length > 0) {
    return false;
  }
  if (
    !localeConfig ||
    !localeConfig.fallback ||
    localeConfig.fallback === LOCALE_CODE
  ) {
    return true;
  }
  if (localeConfig.fallback === 'none') {
    return false;
  }
  const fallbackValue = get(
    context.$locale,
    `${localeConfig.fallback || LOCALE_CODE}.${name}`,
  );
  if (fallbackValue && fallbackValue.length > 0) {
    return false;
  }
  const fallbackLocale = find(localeConfigs, { code: localeConfig.fallback });
  if (fallbackLocale && fallbackLocale !== origLocale) {
    return doesLocaleFallback({
      ...params,
      locale: fallbackLocale,
      origLocale: origLocale || localeConfig,
    });
  }
}

// Used for debugging
// Log different props to try and find the cause of max depth exceeded errors
export function debugFindDiffProps(props, nextProps) {
  for (const key of Object.keys(nextProps)) {
    if (props[key] !== nextProps[key]) {
      console.log('found different prop', key, props[key], nextProps[key]);
    }
  }
}

export function stringIncludes(str, values) {
  return Array.isArray(values)
    ? values.some((value) => str.includes(value))
    : str.includes(values);
}

/**
 * @param {object} user
 * @param {string} permission
 * @param {string} link
 * @param {object?} options
 * @returns {boolean}
 */
export function hasPermission(user, permission, link, options = {}) {
  if (options.isAdvancedUserPermissions) {
    const rolePermissions = get(user, 'role.permissions');

    if (!rolePermissions) {
      return true;
    }

    let permissionValue = rolePermissions[permission];

    if (!permissionValue) {
      if (permission === 'store') {
        permissionValue = rolePermissions.storefront;
      } else if (link) {
        if (link.includes('integrations')) {
          permissionValue = rolePermissions.integrations;
        } else if (
          stringIncludes(link, [
            'models',
            'model-explorer',
            'webhooks',
            'api',
            'console',
          ])
        ) {
          permissionValue = rolePermissions.developer;
        } else {
          const key = link
            .replace(BASE_URI, '')
            .replace('/settings', 'settings');
          permissionValue = rolePermissions[key];
        }
      }
    }

    return permissionValue !== 'restrict';
  }

  if (!permission || user.all_permissions || !user.permissions) {
    return true;
  }

  return user.permissions.includes(permission);
}

/**
 * Determines whether the context user can manage roles and permissions
 * @param {object} context
 * @param {boolean} validateUserPermission
 * @returns {boolean}
 */
export function canUserManagePermissions(
  context,
  validateUserPermission = true,
) {
  const { isOwner, isAdvancedUserPermissions, user } = context;

  // Note: (user.role === 'admin') condition is present to validate legacy admins
  const isAdmin = user.role === 'admin' || user.role?.type === 'admin';
  return Boolean(
    (isOwner || isAdmin) &&
      (!validateUserPermission || isAdvancedUserPermissions),
  );
}

export function normalizeUrl(url, options) {
  // Note: passing a url like www.example.com seems to fail without this
  // Recommend trying to upgrade lib version (but also requires upgrading node)
  let actualUrl = String(url).trim();
  if (!actualUrl.includes('http')) {
    actualUrl = 'https://' + actualUrl.replace(/^.*?\/\//, '');
  }
  try {
    return baseNormalizeUrl(actualUrl, {
      defaultProtocol: 'https',
      stripWWW: false,
      ...options,
    });
  } catch (err) {
    console.error(actualUrl);
    return null;
  }
}

export function isNumber(value) {
  return typeof value === 'number';
}

/**
 * Checks if an SVG content contains a <script> tag.
 * @param {string} content - The SVG content to be checked.
 * @returns {boolean} - Returns false if the SVG content contains a <script> tag, otherwise returns true.
 */
export function validateSVGFile(content) {
  return !content.includes('script');
}

// Renders a table value or a placeholder if empty
export function formatTableValue(value) {
  return value !== null && value !== undefined && value !== '' ? (
    value
  ) : (
    <span className="muted">&mdash;</span>
  );
}

/**
 * Prepares the $locale and $currency parameters according to the client settings.
 * @param {object} client - fetched client
 * @param {boolean} allLocales - use locales
 * @param {boolean} allCurrencies - use currencies
 * @returns {object} - an object with $locale and $currency to use in exporting queries
 */
export function getClientLocaleAndCurrencyParams(
  client,
  allLocales = true,
  allCurrencies = true,
) {
  // use $locale for multiple locales only if option was selected
  const $locale =
    allLocales && client?.localeCodes?.length ? client.localeCodes : undefined;
  // use $currency for multicurrency only if option was selected
  const $currency =
    allCurrencies && client?.currencyCodes?.length
      ? client.currencyCodes
      : undefined;
  return {
    $locale,
    $currency,
  };
}

/**
 * Check if client has multiple currencies.
 * @param {object} client - fetched client
 * @returns {boolean} - client has multiple currencies
 */
export function hasMultipleCurrencies(client) {
  return Boolean(client?.currencyCodes?.length);
}

/**
 * Check if client has multiple locales.
 * @param {object} client - fetched client
 * @returns {boolean} - client has multiple locales
 */
export function hasMultipleLocales(client) {
  return Boolean(client?.localeCodes?.length);
}
