/**
 * @param {Document} templateDocument
 * @param {object} instruction
 */
function processInstruction(templateDocument, instruction) {
  const { selector, remove, processNode } = instruction;
  /** @type {NodeListOf<HTMLElement>} */
  const nodes = templateDocument.querySelectorAll(selector);

  for (const node of nodes) {
    processNode(node);

    if (remove) {
      node.remove();
    }
  }
}

/**
 * @param {Document} templateDocument
 * @param {object[]} instructions
 */
function transformTemplate(templateDocument, instructions) {
  instructions.forEach((instruction) =>
    processInstruction(templateDocument, instruction),
  );
}

/**
 * Helper function to compare two nodes
 *
 * @param {ChildNode} node1
 * @param {ChildNode} node2
 * @returns {boolean}
 */
function areNodesEqual(node1, node2) {
  if (
    node1.nodeType !== node2.nodeType ||
    node1.nodeName !== node2.nodeName ||
    node1.nodeValue !== node2.nodeValue
  ) {
    return false;
  }

  if (node1.attributes && node2.attributes) {
    if (node1.attributes.length !== node2.attributes.length) {
      return false;
    }

    for (let i = 0; i < node1.attributes.length; ++i) {
      const attr1 = node1.attributes[i];
      const attr2 = node2.attributes[i];

      if (attr1.name !== attr2.name || attr1.value !== attr2.value) {
        return false;
      }
    }
  }

  return true;
}

/**
 * @param {ChildNode} node1
 * @param {ChildNode} node2
 */
function swapNodes(node1, node2) {
  const temp = document.createComment('');
  node2.replaceWith(temp);
  node1.replaceWith(node2);
  temp.replaceWith(node1);
}

/**
 * @param {NodeListOf<ChildNode>} nextNodes
 * @param {NodeListOf<ChildNode>} prevNodes
 * @param {[ChildNode, ChildNode[]][]} prevNodesState
 * @param {(node: ChildNode) => boolean} isPortalContainer
 */
function keepPreviousNodes(
  nextNodes,
  prevNodes,
  prevNodesState,
  isPortalContainer,
) {
  const length = Math.min(nextNodes.length, prevNodes.length);

  for (let i = 0; i < length; ++i) {
    const next = nextNodes[i];
    const prev = prevNodes[i];

    if (areNodesEqual(next, prev)) {
      if (typeof next.replaceChildren === 'function') {
        if (isPortalContainer(prev)) {
          // Save node state to return later after transformTemplate
          prevNodesState.push([prev, Array.from(prev.childNodes)]);
        } else {
          // Compare and keep children
          keepPreviousNodes(
            next.childNodes,
            prev.childNodes,
            prevNodesState,
            isPortalContainer,
          );
        }

        // Swap nodes
        swapNodes(next, prev);

        // but not their children
        const nextChildNodes = Array.from(next.childNodes);
        const prevChildNodes = Array.from(prev.childNodes);
        next.replaceChildren(...prevChildNodes);
        prev.replaceChildren(...nextChildNodes);
      }
    }
  }
}

/**
 * @param {string} template
 * @param {object[]} instructions
 * @param {ChildNode[]} [prevElements]
 * @param {(node: ChildNode) => boolean} [isPortalContainer]
 */
function parse(template, instructions, prevElements, isPortalContainer) {
  const parser = new DOMParser();
  const templateDocument = parser.parseFromString(template, 'text/html');
  /** @type {[ChildNode, ChildNode[]][]} */
  const prevNodesState = [];

  if (prevElements) {
    // When provided with previously generated nodes, leave them if possible,
    // as they are necessary for correct rendering of portals.
    keepPreviousNodes(
      templateDocument.body.childNodes,
      prevElements,
      prevNodesState,
      isPortalContainer,
    );
  }

  transformTemplate(templateDocument, instructions);

  if (prevNodesState.length > 0) {
    // Restore current state of containers for portals
    for (const [node, childNodes] of prevNodesState) {
      node.replaceChildren(...childNodes);
    }
  }

  return Array.from(templateDocument.body.childNodes);
}

export default parse;
