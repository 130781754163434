function processBody(bodyItems) {
  return {
    // Pull out body elements
    shouldProcessNode: function (node) {
      return node.name === 'body';
    },
    processNode: function (node, children) {
      bodyItems.push(...children);
      document.body.className = node.attribs.class;
      return false;
    },
  };
}

export default processBody;
