import { ProcessNodeDefinitions } from 'html-to-react';
import { replaceProps } from './utils';

const processNodeDefinitions = ProcessNodeDefinitions();

const processDefaultNode = Object.freeze({
  // Process all other nodes
  shouldProcessNode: () => true,
  processNode(node, children, index) {
    replaceProps(node, false);

    return processNodeDefinitions.processDefaultNode(node, children, index);
  },
});

export default processDefaultNode;
