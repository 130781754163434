import { loadScript } from 'components/storefront/editor/easyblocks-page/parser/utils';
import { getAttributeValue } from './utils';

function processScripts(assetLoaders) {
  return {
    selector: 'script',
    remove: true,
    processNode: (node) => {
      const src = getAttributeValue(node, 'src');
      const type = getAttributeValue(node, 'type');
      const content = node.innerHTML;

      loadScript(src, content, type, assetLoaders);
    },
  };
}

export default processScripts;
