import React, { Fragment } from 'react';
import pt from 'prop-types';

function ContentWrapper({ children }) {
  return <Fragment>{children}</Fragment>;
}

ContentWrapper.propTypes = {
  children: pt.oneOfType([pt.element, pt.array]),
};

export default React.memo(ContentWrapper);
