import React, { useState, useEffect, useCallback } from 'react';
import pt from 'prop-types';
import cx from 'classnames';
import Icon from 'components/icon';
import { FadeOut } from 'components/transitions';
import {
  MESSAGE_EVENTS,
  postStorefrontOverlayAddPageItem,
} from 'components/storefront/editor/utils/post-message';
import AddSectionPopup from './popup';

function AddSectionButton({
  className,
  popup,
  popupLabels,
  popupPaths,
  path,
  onOpenSectionModal: onOpenModal,
  onCloseSectionModal: onCloseModal,
}) {
  const [showPopup, setShowPopup] = useState(false);

  const onShowPopup = useCallback(() => {
    if (!popup) {
      return;
    }

    setShowPopup(true);
  }, [popup]);

  const onClosePopup = useCallback(() => {
    setShowPopup(false);
  }, []);

  const onOpenSectionModal = useCallback(
    (event) => {
      event.stopPropagation();
      onOpenModal();

      const domRect = event.target.getBoundingClientRect();

      postStorefrontOverlayAddPageItem(domRect, path, window.parent);
    },
    [path, onOpenModal],
  );

  useEffect(() => {
    const eventListener = (event) => {
      const { type } = event.data;

      switch (type) {
        case MESSAGE_EVENTS.STOREFRONT_EDITOR.OVERLAY.CLOSED:
          return onCloseModal();

        default:
          return;
      }
    };

    window.addEventListener('message', eventListener);

    return () => {
      window.removeEventListener('message', eventListener);
    };
  }, [onCloseModal]);

  return (
    <div
      className={cx('section-overlay--add-section-button-container', className)}
    >
      {showPopup && (
        <AddSectionPopup
          labels={popupLabels}
          paths={popupPaths}
          onOpenSectionModal={onOpenModal}
          onCloseSectionModal={onCloseModal}
          onClose={onClosePopup}
        />
      )}
      <FadeOut
        className="section-overlay--add-section-button-animation"
        active={showPopup}
        duration={100}
      >
        <button
          className="section-overlay--add-section-button"
          onMouseEnter={onShowPopup}
          onClick={onOpenSectionModal}
        >
          <Icon fa="plus-circle" faType="solid" />
        </button>
      </FadeOut>
    </div>
  );
}

AddSectionButton.propTypes = {
  className: pt.string,
  popup: pt.bool,
  popupLabels: pt.object,
  popupPaths: pt.object,
  path: pt.string,
  onOpenSectionModal: pt.func,
  onCloseSectionModal: pt.func,
};

export default React.memo(AddSectionButton);
