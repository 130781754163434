import { handleForm } from 'components/storefront/editor/easyblocks-page/parser/utils';

import { getAttributeValue, applyAttributes } from './utils';

function processForm(theme) {
  return {
    selector: 'form',
    /** @param {HTMLElement} node */
    processNode(node) {
      const action = getAttributeValue(node, 'action');

      applyAttributes(node, handleForm(theme, action));
    },
  };
}

export default processForm;
