import React from 'react';
import { ContentWrapper } from 'components/storefront/editor/easyblocks-page/components';
import parseHtmlToReact, {
  processLayoutGroupSections,
  processStylesheet,
  processStyle,
  processScripts,
  processBody,
  processLayoutContent,
  processHtml,
  processForm,
  processButton,
  processLink,
  processCustomNode,
  processDefaultNode,
} from 'components/storefront/editor/easyblocks-page/parser/html-to-react';

function getInstructions(theme, pageContentString, assetLoaders) {
  let bodyItems = [];

  return [
    processLayoutGroupSections,
    processStylesheet(assetLoaders),
    processStyle(assetLoaders),
    processScripts(assetLoaders),
    processBody(bodyItems),
    processLayoutContent(pageContentString),
    processHtml(bodyItems),
    processForm(theme),
    processButton(theme),
    processLink(theme),
    processCustomNode,
    processDefaultNode,
  ];
}

function parse(theme, template, pageContentString) {
  const assetLoaders = [];

  const RootElements = parseHtmlToReact(
    template,
    getInstructions(theme, pageContentString, assetLoaders),
  );

  const Content = RootElements.find(
    (element) => element.type === ContentWrapper,
  ) || {
    type: () => (
      // TODO: nicer UI for rendering this error
      <div>Theme layout is missing a valid &lt;html&gt; tag</div>
    ),
    props: {},
  };

  return { Content, assetLoaders };
}

export default parse;
