import React, { useEffect, useCallback, useState, useMemo } from 'react';
import FroalaEditor from 'react-froala-wysiwyg';
import pt from 'prop-types';

import { changeFormValue } from 'components/storefront/editor/utils/easyblocks';

import { isMultilineInlineEditableType, getToolbarButtons } from './utils';

import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/froala_editor.pkgd.min.css';
import 'froala-editor/js/plugins/link.min.js';
import 'froala-editor/js/plugins/paragraph_format.min.js';
import 'froala-editor/js/plugins/lists.min.js';

const { REACT_APP_FROALA_KEY } = process.env;
const DEFAULT_LOCALE = 'en-US';

function InlineEditable(props) {
  const { type, path, fieldId, content, locale, setEditing } = props;

  useEffect(() => {
    return () => {
      setEditing(false);
    };
  }, [setEditing]);

  const [, setLocalEditing] = useState(false);

  const config = useMemo(() => {
    function updateEditing(editing) {
      setEditing(editing);
      setLocalEditing(editing);
    }

    return {
      key: REACT_APP_FROALA_KEY,
      toolbarInline: true,
      toolbarVisibleWithoutSelection: false,
      fontFamilyDefaultSelection: '',
      fontSizeDefaultSelection: '',
      multiline: isMultilineInlineEditableType(type),
      toolbarButtons: getToolbarButtons(type),
      htmlUntouched: true,
      events: {
        focus() {
          updateEditing(true);
        },
        blur() {
          updateEditing(false);
        },
        keydown(event) {
          event.stopPropagation();
        },
      },
    };
  }, [type, setEditing]);

  const localizedPath = useMemo(() => {
    if (locale === DEFAULT_LOCALE) {
      return `${path}.${fieldId}.value`;
    }

    return `${path}.$locale.value.${locale}.${fieldId}`;
  }, [path, fieldId, locale]);

  const onChange = useCallback(
    (text) => {
      setLocalEditing((editing) => {
        if (editing) {
          changeFormValue(localizedPath, text, window.parent);
        }

        return editing;
      });
    },
    [localizedPath],
  );

  return (
    <FroalaEditor
      tag="textarea"
      config={config}
      model={content}
      onModelChange={onChange}
    />
  );
}

InlineEditable.propTypes = {
  type: pt.string,
  path: pt.string,
  fieldId: pt.string,
  content: pt.string,
  locale: pt.string,
  setEditing: pt.func,
};

export default React.memo(InlineEditable);
