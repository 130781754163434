import parseDOM, {
  processInlineEditable,
} from 'components/storefront/editor/easyblocks-page/parser/dom';

import { INLINE_EDITABLE_ATTRIBUTE } from 'components/storefront/editor/easyblocks-page/parser/dom/instructions/inline-editable';

function getInstructions(blockConfig, inlineEditableConfigs) {
  return [processInlineEditable(blockConfig, inlineEditableConfigs)];
}

/**
 * @param {ChildNode} node
 * @returns {boolean}
 */
function isPortalContainer(node) {
  if (node.attributes) {
    return node.attributes.getNamedItem(INLINE_EDITABLE_ATTRIBUTE) !== null;
  }

  return false;
}

function parse(template, blockConfig, prevElements) {
  const inlineEditableConfigs = [];

  const elements = parseDOM(
    template,
    getInstructions(blockConfig, inlineEditableConfigs),
    prevElements,
    isPortalContainer,
  );

  return { elements, inlineEditableConfigs };
}

export default parse;
