import React, { useEffect, useState, useMemo, useRef } from 'react';
import { createPortal } from 'react-dom';
import pt from 'prop-types';

import InlineEditable, {
  useInlineEditable,
} from 'components/storefront/editor/easyblocks-page/components/inline-editable';

import parse from './parser';

function BlockContent(props) {
  const { html, blockConfigs, blockData, path, locale } = props;
  const { setEditing } = useInlineEditable();

  const blockConfig = useMemo(() => {
    const type = blockData?.type;

    if (!type) {
      return null;
    }

    const blockConfig = blockConfigs[type];

    if (!blockConfig) {
      return null;
    }

    blockConfig.path = path;

    return blockConfig;
  }, [blockData, blockConfigs, path]);

  const contentRef = useRef(null);
  const [, setElements] = useState([]);
  const [portals, setPortals] = useState([]);

  useEffect(() => {
    if (!html || !contentRef.current) {
      return;
    }

    setElements((prevElements) => {
      const { elements, inlineEditableConfigs } = parse(
        html,
        blockConfig,
        prevElements,
      );

      contentRef.current.replaceChildren(...elements);

      const portals = inlineEditableConfigs.map((config) => {
        const { container, content, type, path, fieldId } = config;

        return createPortal(
          <InlineEditable
            type={type}
            path={path}
            fieldId={fieldId}
            content={content}
            locale={locale}
            setEditing={setEditing}
          />,
          container,
          path,
        );
      });

      setPortals(portals);

      return elements;
    });
  }, [html, blockConfig, locale, setEditing]);

  return (
    <>
      <div ref={contentRef} className="swell-page-block-content" />
      {portals}
    </>
  );
}

BlockContent.propTypes = {
  html: pt.string,
  blockConfigs: pt.object,
  blockData: pt.object,
  path: pt.string,
  locale: pt.string,
};

export default React.memo(BlockContent);
