export const blockClass = 'swell-block';

function processBlock(blockElements) {
  return {
    selector: `.${blockClass}`,
    processNode: (node) => {
      blockElements.push({ container: node, html: node.innerHTML });

      node.innerHTML = '';
    },
  };
}

export default processBlock;
