/**
 * @param {string} path
 * @returns {[group: string, index: number, isBlock: boolean]}
 */
export function parsePath(path) {
  const [group, index, isBlock] = path.split('.');

  return [group, Number(index), Boolean(isBlock)];
}

/**
 * @param {string} group
 * @param {object[]} sectionGroups
 */
function findSectionGroupIndex(group, sectionGroups) {
  return sectionGroups.findIndex((sectionGroup) => sectionGroup.prop === group);
}

/**
 * @param {string} group
 * @param {object[]} sectionGroups
 * @returns {string | null}
 */
export function getTopGroup(group, sectionGroups) {
  const index = findSectionGroupIndex(group, sectionGroups);

  if (index <= 0) {
    return null;
  }

  return sectionGroups[index - 1].prop;
}

/**
 * @param {number} index
 * @returns {boolean}
 */
export function isFirstSection(index) {
  return index === 0;
}

/**
 * @param {string} group
 * @param {object[]} sectionGroups
 * @param {number} index
 * @returns {boolean}
 */
export function isFirstHeaderSection(group, sectionGroups, index) {
  if (sectionGroups.length <= 0) {
    return false;
  }

  const first = sectionGroups[0];
  return group === first.prop && isFirstSection(index);
}

/**
 * @param {number} index
 * @param {number} sectionsLength
 * @returns {boolean}
 */
export function isLastSection(index, sectionsLength) {
  return index === sectionsLength - 1;
}

/**
 * @param {string} group
 * @param {object[]} sectionGroups
 * @param {number} index
 * @param {number} sectionsLength
 * @returns {boolean}
 */
export function isLastFooterSection(
  group,
  sectionGroups,
  index,
  sectionsLength,
) {
  if (sectionGroups.length <= 0) {
    return false;
  }

  const last = sectionGroups[sectionGroups.length - 1];
  return group === last.prop && isLastSection(index, sectionsLength);
}

/**
 * @param {string} group
 * @param {number} index
 * @returns {string}
 */
export function getTopButtonPath(group, index) {
  return `${group}.${index}`;
}

/**
 * @param {string} group
 * @param {number} index
 * @returns {string}
 */
export function getBottomButtonPath(group, index) {
  return `${group}.${index + 1}`;
}

/**
 * @param {string} group
 * @param {object[]} sectionGroups
 * @returns {{ topLabel: string, bottomLabel: string } | null}
 */
export function getTopButtonPopupLabels(group, sectionGroups) {
  const index = findSectionGroupIndex(group, sectionGroups);

  if (index <= 0) {
    return null;
  }

  return {
    topLabel: sectionGroups[index - 1].label,
    bottomLabel: sectionGroups[index].label,
  };
}

/**
 * @param {string} group
 * @param {object[]} sectionGroups
 * @param {number} topGroupSectionsLength
 * @returns {{ topPath: string, bottomPath: string } | null}
 */
export function getTopButtonPopupPaths(
  group,
  sectionGroups,
  topGroupSectionsLength,
) {
  const index = findSectionGroupIndex(group, sectionGroups);

  if (index <= 0) {
    return null;
  }

  return {
    topPath: `${sectionGroups[index - 1].prop}.${topGroupSectionsLength}`,
    bottomPath: `${sectionGroups[index].prop}.0`,
  };
}

/**
 * @param {string} group
 * @param {object[]} sectionGroups
 * @returns {{ topLabel: string, bottomLabel: string } | null}
 */
export function getBottomButtonPopupLabels(group, sectionGroups) {
  const index = findSectionGroupIndex(group, sectionGroups);

  if (index >= sectionGroups.length - 1) {
    return null;
  }

  return {
    topLabel: sectionGroups[index].label,
    bottomLabel: sectionGroups[index + 1].label,
  };
}

/**
 * @param {string} group
 * @param {object[]} sectionGroups
 * @param {number} index
 * @returns {{ topPath: string, bottomPath: string } | null}
 */
export function getBottomButtonPopupPaths(group, sectionGroups, index) {
  const idx = findSectionGroupIndex(group, sectionGroups);

  if (idx >= sectionGroups.length - 1) {
    return null;
  }

  return {
    topPath: `${sectionGroups[idx].prop}.${index + 1}`,
    bottomPath: `${sectionGroups[idx + 1].prop}.0`,
  };
}
