const INLINE_EDITABLE_TYPES = Object.freeze({
  singleline: ['text', 'short_text'],
  multiline: ['textarea', 'long_text', 'basic_html', 'rich_text', 'rich_html'],
});

const TOOLBAR_BUTTONS = Object.freeze({
  singleline: ['bold', 'italic', 'underline'],
  multiline: ['bold', 'italic', 'paragraphFormat', 'formatUL', 'formatOL'],
});

export function isSinglelineInlineEditableType(type) {
  return INLINE_EDITABLE_TYPES.singleline.includes(type);
}

export function isMultilineInlineEditableType(type) {
  return INLINE_EDITABLE_TYPES.multiline.includes(type);
}

export function isInlineEditableType(type) {
  return (
    isSinglelineInlineEditableType(type) || isMultilineInlineEditableType(type)
  );
}

export function getToolbarButtons(type) {
  return isSinglelineInlineEditableType(type)
    ? TOOLBAR_BUTTONS.singleline
    : TOOLBAR_BUTTONS.multiline;
}
