import { handleButton } from 'components/storefront/editor/easyblocks-page/parser/utils';

import { getAttributeValue, applyAttributes } from './utils';

function processButton(theme) {
  return {
    selector: 'button',
    /** @param {HTMLElement} node */
    processNode(node) {
      const form = getAttributeValue(node, 'form');
      const type = getAttributeValue(node, 'type');

      applyAttributes(node, handleButton(theme, form, type));
    },
  };
}

export default processButton;
