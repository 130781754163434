import React from 'react';

import { handleButton } from 'components/storefront/editor/easyblocks-page/parser/utils';

import { replaceProps, applyAttributes } from './utils';

/** @typedef {import('domhandler').Element} Element */

function processButton(theme) {
  return {
    /** @param {Element} node */
    shouldProcessNode(node) {
      return node.name === 'button';
    },
    /** @param {Element} node */
    processNode(node, children, index) {
      const { form, type } = node.attribs;

      applyAttributes(node, handleButton(theme, form, type));
      replaceProps(node);

      return React.createElement(node.name, node.attribs, ...children);
    },
  };
}

export default processButton;
