/**
 * @param {Element} node
 * @param {string} attribute
 * @returns {unknown}
 */
export function getAttributeValue(node, attribute) {
  return node?.attributes?.[attribute]?.value;
}

/**
 * @param {Element} node
 * @param {[string, unknown][]} attrs
 */
export function applyAttributes(node, attrs) {
  for (const [attr, value] of attrs) {
    if (attr.startsWith('on')) {
      node[attr] = value;
    } else {
      node.setAttribute(attr, value);
    }
  }
}
