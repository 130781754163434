import React from 'react';
import { find } from 'lodash';
import { normalizeUrl } from 'utils';

const { APP_DEPLOYMENT_PUBLIC_URL, CLIENT_URL, APP_PAGES_HOST_DEV } =
  process.env;

const THEME_LINKS = {
  origin: 'https://github.com/swellstores/origin-theme/',
  horizon: 'https://github.com/swellstores/horizon',
};

export function getStorefrontURL(storefront, preview = false, editor = false) {
  if (!storefront) return '';

  const {
    id,
    slug,
    primary,
    domains,
    external,
    hosted,
    app_id,
    client_id,
    environment_id,
  } = storefront;

  if (APP_PAGES_HOST_DEV) {
    return APP_PAGES_HOST_DEV;
  }

  // External storefronts
  if (!app_id && external?.custom && external?.custom_url) {
    return external.custom_url;
  }

  // Primary with domain
  if (primary && !preview) {
    const primaryDomain = find(domains?.results, {
      primary: true,
      verified: true,
    });
    if (primaryDomain) {
      return normalizeUrl(primaryDomain.name, {
        defaultProtocol: 'https://',
      });
    }
  }

  // V1 hosted storefronts
  if (hosted) {
    return CLIENT_URL.replace('CLIENT_ID', slug);
  }

  if (editor) {
    return CLIENT_URL.replace('CLIENT_ID', `${client_id}--${id}--dev`);
  } else if (preview) {
    return CLIENT_URL.replace('CLIENT_ID', `${client_id}--${id}--preview`);
  } else if (!primary || environment_id) {
    return CLIENT_URL.replace('CLIENT_ID', `${client_id}--${id}`);
  }

  return CLIENT_URL.replace('CLIENT_ID', client_id);
}

export function getLocalProxyStorefrontURL(session, editor = false) {
  const { id, client_id, local_proxy } = session;

  if (local_proxy) {
    if (editor) {
      return CLIENT_URL.replace('CLIENT_ID', `${client_id}--${id}--local--dev`);
    } else {
      return CLIENT_URL.replace('CLIENT_ID', `${client_id}--${id}--local`);
    }
  }

  return '';
}

export function storefrontPublicDeploymentURL(storefront) {
  const { preview_deployment_name } = storefront;
  return APP_DEPLOYMENT_PUBLIC_URL.replace(
    'DEPLOYMENT_ID',
    preview_deployment_name,
  );
}

export function storefrontCheckoutURL(clientId, dev = false) {
  return CLIENT_URL.replace('CLIENT_ID', `${clientId}${dev ? '--dev' : ''}`);
}

export function renderStorefrontV1ThemeDesc(storefront) {
  return (
    <>
      <a
        href={THEME_LINKS[storefront.source_id]}
        target="_blank"
        rel="noreferrer"
      >
        {storefront.source?.name || storefront.source_id} theme
      </a>{' '}
      v{storefront.source_version}
    </>
  );
}

export function getPageIdFromRoute(route, pages) {
  return find(pages, (p) => {
    if (p.url.match(':slug')) {
      return route.includes(p.url.split('/')[1]);
    } else {
      return p.url === route;
    }
  })?.id;
}
