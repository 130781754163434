const IGNORED_WARNINGS = [
  'componentWillMount has been renamed',
  'componentWillUpdate has been renamed',
  'componentWillReceiveProps has been renamed',
];

const IGNORED_ERRORS = [
  'uses the legacy childContextTypes API',
  'uses the legacy contextTypes API',
  'Support for string refs will be removed',
];

function isIgnoredString(consoleArgs, ignoredWarnings) {
  return (
    consoleArgs.length > 0 &&
    typeof consoleArgs[0] === 'string' &&
    ignoredWarnings.some((warning) => consoleArgs[0].includes(warning))
  );
}

/**
 * Mute annoying React deprecation warnings
 */
export function muteReactWarnings() {
  const originalWarn = console.warn;
  console.warn = (...args) => {
    if (isIgnoredString(args, IGNORED_WARNINGS)) {
      return;
    }
    originalWarn(...args);
  };

  const originalError = console.error;
  console.error = (...args) => {
    if (isIgnoredString(args, IGNORED_ERRORS)) {
      return;
    }
    originalError(...args);
  };
}
