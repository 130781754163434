import parseDOM, {
  processStylesheet,
  processStyle,
  processScripts,
  processForm,
  processButton,
  processLink,
  processBlock,
} from 'components/storefront/editor/easyblocks-page/parser/dom';

import { blockClass } from 'components/storefront/editor/easyblocks-page/parser/dom/instructions/block';

/** @typedef {import('@swell/apps-sdk').SwellTheme} SwellTheme */

/**
 * @param {SwellTheme} theme
 * @param {object[]} blockElements
 * @param {(() => Promise<void>)[]} assetLoaders
 */
function getInstructions(theme, blockElements, assetLoaders) {
  return [
    processStylesheet(assetLoaders),
    processStyle(assetLoaders),
    processScripts(assetLoaders),
    processForm(theme),
    processButton(theme),
    processLink(theme),
    processBlock(blockElements),
  ];
}

/**
 * @param {ChildNode} node
 * @returns {boolean}
 */
function isPortalContainer(node) {
  if (node.classList) {
    return node.classList.contains(blockClass);
  }

  return false;
}

/**
 * @param {string} template
 * @param {SwellTheme} theme
 * @param {ChildNode[]} [prevElements]
 */
function parse(template, theme, prevElements) {
  /** @type {object[]} */
  const blockElements = [];
  /** @type {(() => Promise<void>)[]} */
  const assetLoaders = [];

  const elements = parseDOM(
    template,
    getInstructions(theme, blockElements, assetLoaders),
    prevElements,
    isPortalContainer,
  );

  return { elements, blockElements, assetLoaders };
}

export default parse;
