import { handleLink } from 'components/storefront/editor/easyblocks-page/parser/utils';

import { getAttributeValue, applyAttributes } from './utils';

function processLink(theme) {
  return {
    selector: 'a',
    /** @param {HTMLElement} node */
    processNode(node) {
      const href = getAttributeValue(node, 'href');

      applyAttributes(node, handleLink(theme, href));
    },
  };
}

export default processLink;
