import React, { Fragment, useMemo } from 'react';
import pt from 'prop-types';
import { EasyblocksEditor } from '@swell/easyblocks-editor';
import {
  getEasyblocksPagePropsWithConfigs,
  getEasyblocksComponentDefinitions,
} from '@swell/apps-sdk';
import { initGlobalHandlers } from 'components/storefront/editor/easyblocks-page/parser/utils';
import {
  Global,
  getRootComponent,
  getSectionComponent,
  Block,
} from './components';

import './easyblocks-page.scss';

export function getEasyblocksComponents(props) {
  const { theme, themeGlobals, allSections, layoutSectionGroups } = props;

  theme.setGlobals(themeGlobals);

  if (themeGlobals?.shopify_compatibility && !theme.shopifyCompatibility) {
    theme.shopifyCompatibility = new theme.shopifyCompatibilityClass(theme);
  }

  // globally replace fetch and assign error handler
  initGlobalHandlers(theme);

  return getEasyblocksComponentDefinitions(
    allSections,
    layoutSectionGroups,
    (type, data) => {
      switch (type) {
        case 'global':
          return Global;
        case 'root':
          return getRootComponent(props, theme);
        case 'pageSection':
          return getSectionComponent(props, data, theme);
        case 'block':
          return Block;
        // not necessary
        case 'layoutSectionGroup':
          return () => Fragment;
        default:
          throw new Error(`Invalid component definition type: ${type}`);
      }
    },
  );
}

function EasyblocksPage(props) {
  const {
    pageId,
    allSections,
    pageSections,
    layoutSectionGroups,
    themeGlobals,
  } = props;

  const easyblocksConfig = useMemo(() => {
    const { easyblocksConfig } = getEasyblocksPagePropsWithConfigs(
      themeGlobals,
      allSections,
      pageSections,
      layoutSectionGroups,
      pageId,
    );

    return easyblocksConfig;
  }, [pageId, themeGlobals, allSections, pageSections, layoutSectionGroups]);

  const components = useMemo(() => {
    return getEasyblocksComponents(props);
  }, [props]);

  return (
    <EasyblocksEditor
      config={easyblocksConfig}
      components={components}
      __debug={true}
      isCanvas={true}
    />
  );
}

EasyblocksPage.propTypes = {
  pageId: pt.string,
  allSections: pt.array,
  pageSections: pt.array,
  layoutSectionGroups: pt.array,
  themeGlobals: pt.object,
};

export default React.memo(EasyblocksPage);
