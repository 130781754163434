import React, { useState, useEffect, useRef, useCallback } from 'react';
import pt from 'prop-types';
import cx from 'classnames';
import {
  MESSAGE_EVENTS,
  postStorefrontItemHover,
  postStorefrontItemUnhover,
} from 'components/storefront/editor/utils/post-message';
import AddSectionButtons from './add-section-buttons';

import './section-overlay.scss';

const SCROLL_OFFSET = 100;

function SectionOverlay({ path, selected, blockSelected, sectionGroups }) {
  const [sidebarHovered, setSidebarHovered] = useState(false);
  const [showSectionModal, setShowSectionModal] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    const eventListener = (event) => {
      const { type, payload } = event.data;

      switch (type) {
        case MESSAGE_EVENTS.STOREFRONT_EDITOR.ITEM.HOVER:
          return payload.path.includes(path) && setSidebarHovered(true);

        case MESSAGE_EVENTS.STOREFRONT_EDITOR.ITEM.UNHOVER:
          return setSidebarHovered(false);

        default:
          return;
      }
    };

    window.addEventListener('message', eventListener);

    return () => {
      window.removeEventListener('message', eventListener);
    };
  }, [path]);

  useEffect(() => {
    if (!ref.current || (!selected && !blockSelected)) {
      return;
    }

    const rect = ref.current.getBoundingClientRect();

    window.scrollTo({
      top: rect.top + window.scrollY - SCROLL_OFFSET,
      behavior: 'smooth',
    });
  }, [selected, blockSelected]);

  const onMouseEnter = useCallback(() => {
    postStorefrontItemHover(path, window.parent);
  }, [path]);

  const onMouseLeave = useCallback(() => {
    if (!showSectionModal) {
      return postStorefrontItemUnhover(path, window.parent);
    }
  }, [path, showSectionModal]);

  const onOpenSectionModal = useCallback(() => {
    setShowSectionModal(true);
  }, []);

  const onCloseSectionModal = useCallback(() => {
    setShowSectionModal(false);
  }, []);

  return (
    <div
      className={cx('section-overlay', {
        selected,
        'block-selected': blockSelected,
        hovered: sidebarHovered || showSectionModal,
        'with-buttons': showSectionModal,
      })}
      ref={ref}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {!selected && !blockSelected && (
        <AddSectionButtons
          path={path}
          sectionGroups={sectionGroups}
          onOpenSectionModal={onOpenSectionModal}
          onCloseSectionModal={onCloseSectionModal}
        />
      )}
    </div>
  );
}

SectionOverlay.propTypes = {
  path: pt.string,
  selected: pt.bool,
  blockSelected: pt.bool,
  sectionGroups: pt.array,
};

export default React.memo(SectionOverlay);
