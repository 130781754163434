import React from 'react';
import { convertCssToReactStyle } from './utils';

const processCustomNode = Object.freeze({
  // "html-to-react" considers nodes as custom if the node name contains "-", and does not process it.
  // Tags containing "-" are Web Components and do not require prop replacement.
  shouldProcessNode: (node) => node.name?.includes('-'),
  processNode(node, children, index) {
    if (node.attribs) {
      node.attribs.key = index;

      if (typeof node.attribs.style === 'string') {
        node.attribs.style = convertCssToReactStyle(node.attribs.style);
      }
    }

    return React.createElement(node.name, node.attribs, ...children);
  },
});

export default processCustomNode;
