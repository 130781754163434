/**
 * @param {SwellTheme} theme
 * @param {unknown} sectionData
 */
export function compileSectionData(theme, sectionData) {
  const compileData = async (data) => {
    if (typeof data === 'string') {
      return theme.liquidSwell.parseAndRender(data);
    }

    if (Array.isArray(data)) {
      return Promise.all(data.map(compileData));
    }

    if (data && typeof data === 'object' && !data._getterHash) {
      const entries = await Promise.all(
        Object.entries(data).map(async ([key, value]) => {
          return [key, await compileData(value)];
        }),
      );
      return Object.fromEntries(entries);
    }

    return data;
  };

  return compileData(sectionData);
}
