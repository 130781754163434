import React from 'react';
import { ContentWrapper } from 'components/storefront/editor/easyblocks-page/components';

function processHtml(bodyItems) {
  return {
    // Replace HTML tag with head and body elements
    shouldProcessNode: function (node) {
      return node.name === 'html';
    },
    processNode: function (_node, _children, index) {
      // TODO: try getting rid of this
      document.documentElement.setAttribute('class', 'js');

      const children = bodyItems.filter(
        (item) =>
          item && typeof item !== 'string' && React.isValidElement(item),
      );

      return React.createElement(ContentWrapper, { key: index }, ...children);
    },
  };
}

export default processHtml;
