import { loadStylesheet } from 'components/storefront/editor/easyblocks-page/parser/utils';
import { getAttributeValue } from './utils';

function processStylesheet(assetLoaders) {
  return {
    selector: 'link[rel="stylesheet"]',
    remove: true,
    processNode: (node) => {
      const href = getAttributeValue(node, 'href');

      loadStylesheet(href, assetLoaders);
    },
  };
}

export default processStylesheet;
