import React, { useContext } from 'react';
import pt from 'prop-types';
import { RootContext } from 'components/storefront/editor/easyblocks-page/components/root';

const SECTION_GROUP_ID_REGEX = /^swell-section-group__(.+)$/;

function getSectionId(component, isShopify) {
  return `${isShopify ? 'shopify' : 'swell'}-section-sections__${component}`;
}

function getSectionClassName(component, groupId, isShopify) {
  return isShopify
    ? `shopify-section shopify-section-group-${groupId} section-${component}`
    : `swell-section swell-section-group-${groupId} section-${component}`;
}

function SectionGroup(props) {
  const { id } = props;
  const rootProps = useContext(RootContext);

  const match = id?.match(SECTION_GROUP_ID_REGEX);

  if (!match) {
    return null;
  }

  const [, sectionGroupId] = match;
  const isShopify = Boolean(rootProps.theme.shopifyCompatibility);
  const groupSections = rootProps[`SectionGroup_${sectionGroupId}`];

  if (!Array.isArray(groupSections)) {
    return null;
  }

  return groupSections.map((Section) => {
    const { _component: component } = Section.props.compiled;
    const sectionId = getSectionId(component, isShopify);
    const sectionClassName = getSectionClassName(
      component,
      sectionGroupId,
      isShopify,
    );

    const SectionComponent = Section.type;

    return (
      <SectionComponent
        {...Section.props}
        key={sectionId}
        id={sectionId}
        className={sectionClassName}
      />
    );
  });
}

SectionGroup.propTypes = {
  id: pt.string,
  children: pt.oneOfType([pt.element, pt.array]),
};

export default React.memo(SectionGroup);
