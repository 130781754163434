import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Router, browserHistory } from 'react-router';
import { muteReactWarnings } from 'utils/react';

import StorefrontThemeEditorPage from 'containers/StorefrontThemeEditorPage';
import { STOREFRONT_EDITOR_CANVAS_PATH } from 'components/storefront/editor/utils/editor';

function isStorefrontThemeEditorCanvas() {
  return window.location.href.includes(STOREFRONT_EDITOR_CANVAS_PATH);
}

class App extends React.PureComponent {
  state = {
    loaded: false,
    store: null,
    routes: null,
  };

  async componentDidMount() {
    // Dynamically import modules to isolate CSS styles
    const [routes, store] = await Promise.all([
      import('./routes/instance'),
      import('./store/instance'),
      import('./styles/global.scss'),
    ]);

    this.setState({
      routes: routes.default,
      store: store.default,
      loaded: true,
    });
  }

  render() {
    const { loaded, store, routes } = this.state;

    if (!loaded) {
      return null;
    }

    return (
      <Provider store={store}>
        <Router history={browserHistory} routes={routes} />
      </Provider>
    );
  }
}

function getRootComponent() {
  if (isStorefrontThemeEditorCanvas()) {
    return <StorefrontThemeEditorPage />;
  }

  return <App />;
}

muteReactWarnings();

ReactDOM.render(getRootComponent(), document.getElementById('root'));

if (module.hot) {
  module.hot.accept();
}
