import { loadScript } from 'components/storefront/editor/easyblocks-page/parser/utils';
import { isLayoutSectionAsset } from './utils';

function processScripts(assetLoaders) {
  return {
    shouldProcessNode: function (node) {
      return node.name === 'script' && !isLayoutSectionAsset(node);
    },
    processNode: function (node) {
      const src = node.attribs.src || null;
      const type = node.attribs.type || null;
      const content = node.children.length > 0 ? node.children[0].data : null;

      loadScript(src, content, type, assetLoaders);

      return null;
    },
  };
}

export default processScripts;
