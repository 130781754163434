import React, { useContext } from 'react';
import pt from 'prop-types';
import cx from 'classnames';
import { RootContext } from 'components/storefront/editor/easyblocks-page/components/root';
import { CONTENT_SECTIONS_PREFIX } from 'components/storefront/editor/utils/easyblocks';
import { postStorefrontOverlayAddPageItem } from 'components/storefront/editor/utils/post-message';

function getSectionPlaceholderProps(path) {
  const onClick = () => {
    const placeholder = document.querySelector('section.section-placeholder');

    if (!placeholder) {
      return;
    }

    const domRect = placeholder.getBoundingClientRect();

    postStorefrontOverlayAddPageItem(domRect, path, window.parent, {
      offsetY: domRect.height / 2 - 10,
      offsetX: -(domRect.width / 2) + 60,
    });
  };

  return {
    onClick,
  };
}

function getPlaceholderProps(type, path) {
  switch (type) {
    case 'section':
      return getSectionPlaceholderProps(path);
    default:
      return {};
  }
}

function getPlaceholderType(label) {
  switch (label) {
    case 'Add section':
      return 'section';
    default:
      return null;
  }
}

function TypePlaceholderComponent({ Section, type, path }) {
  const SectionComponent = Section.type;

  return (
    <section
      className={cx('type-placeholder', {
        [`${type}-placeholder`]: Boolean(type),
      })}
    >
      <SectionComponent
        {...Section.props}
        {...getPlaceholderProps(type, path)}
      />
    </section>
  );
}

TypePlaceholderComponent.propTypes = {
  Section: pt.element,
  type: pt.string,
  path: pt.string,
};

const TypePlaceholder = React.memo(TypePlaceholderComponent);

function LayoutContent() {
  const rootProps = useContext(RootContext);
  const { ContentSections } = rootProps;

  return ContentSections.map((Section, index) => {
    const placeholderType = getPlaceholderType(
      Section.props?.appearance?.label,
    );

    if (placeholderType) {
      return (
        <TypePlaceholder
          key={index}
          type={placeholderType}
          Section={Section}
          path={`${CONTENT_SECTIONS_PREFIX}.${index}`}
        />
      );
    }

    const SectionComponent = Section.type;

    return (
      <section className="section" key={index}>
        <SectionComponent {...Section.props} />
      </section>
    );
  });
}

export default React.memo(LayoutContent);
