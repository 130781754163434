import React from 'react';
import pt from 'prop-types';

import { parsePath } from './utils';
import TopAddSectionButton from './top-button';
import BottomAddSectionButton from './bottom-button';

import './buttons.scss';

function AddSectionButtons({
  path,
  sectionGroups,
  onOpenSectionModal,
  onCloseSectionModal,
}) {
  const [group, index, isBlock] = parsePath(path);

  if (isBlock) {
    return null;
  }

  return (
    <div className="section-overlay--add-section-buttons">
      <TopAddSectionButton
        group={group}
        index={index}
        sectionGroups={sectionGroups}
        onOpenSectionModal={onOpenSectionModal}
        onCloseSectionModal={onCloseSectionModal}
      />

      <BottomAddSectionButton
        group={group}
        index={index}
        sectionGroups={sectionGroups}
        onOpenSectionModal={onOpenSectionModal}
        onCloseSectionModal={onCloseSectionModal}
      />
    </div>
  );
}

AddSectionButtons.propTypes = {
  path: pt.string,
  sectionGroups: pt.array,
  onOpenSectionModal: pt.func,
  onCloseSectionModal: pt.func,
};

export default React.memo(AddSectionButtons);
