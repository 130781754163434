import React, { useEffect, useCallback } from 'react';
import pt from 'prop-types';
import cx from 'classnames';
import Icon from 'components/icon';
import {
  MESSAGE_EVENTS,
  postStorefrontOverlayAddPageItem,
} from 'components/storefront/editor/utils/post-message';

function AddBlockButton({
  className,
  path,
  onOpenBlockModal: onOpenModal,
  onCloseBlockModal: onCloseModal,
}) {
  const onOpenBlockModal = useCallback(
    (event) => {
      event.stopPropagation();
      onOpenModal();

      const domRect = event.target.getBoundingClientRect();

      postStorefrontOverlayAddPageItem(domRect, path, window.parent);
    },
    [path, onOpenModal],
  );

  useEffect(() => {
    const eventListener = (event) => {
      const { type } = event.data;

      switch (type) {
        case MESSAGE_EVENTS.STOREFRONT_EDITOR.OVERLAY.CLOSED:
          return onCloseModal();

        default:
          return;
      }
    };

    window.addEventListener('message', eventListener);

    return () => {
      window.removeEventListener('message', eventListener);
    };
  }, [onCloseModal]);

  return (
    <div className={cx('block-overlay--add-block-button-container', className)}>
      <button
        className="block-overlay--add-block-button"
        onClick={onOpenBlockModal}
      >
        <Icon fa="plus-circle" faType="solid" />
      </button>
    </div>
  );
}

AddBlockButton.propTypes = {
  className: pt.string,
  path: pt.string,
  onOpenBlockModal: pt.func,
  onCloseBlockModal: pt.func,
};

export default React.memo(AddBlockButton);
