import React from 'react';
import { SectionGroup } from 'components/storefront/editor/easyblocks-page/components';
import { replaceProps } from './utils';

const processLayoutGroupSections = Object.freeze({
  // Replace layout group sections with the actual components
  shouldProcessNode: function (node) {
    return node?.attribs?.id?.startsWith('swell-section-group');
  },
  processNode: function (node, children, index) {
    replaceProps(node);

    return React.createElement(
      SectionGroup,
      {
        ...node.attribs,
        key: index,
      },
      children,
    );
  },
});

export default processLayoutGroupSections;
