import React, { useState, useEffect, useCallback } from 'react';
import pt from 'prop-types';
import { FadeIn } from 'components/transitions';
import { MESSAGE_EVENTS } from 'components/storefront/editor/utils/post-message';
import AddSectionPopupButton from './popup-button';

function AddSectionPopup({
  labels,
  paths,
  onOpenSectionModal: onOpenModal,
  onCloseSectionModal: onCloseModal,
  onClose,
}) {
  const [showSectionModal, setShowSectionModal] = useState(false);

  const onOpenSectionModal = useCallback(() => {
    setShowSectionModal(true);
    onOpenModal();
  }, [onOpenModal]);

  const onCloseSectionModal = useCallback(() => {
    setShowSectionModal(false);
    onCloseModal();
    onClose();
  }, [onCloseModal, onClose]);

  const onClosePopup = useCallback(() => {
    if (!showSectionModal) {
      return onClose();
    }
  }, [showSectionModal, onClose]);

  useEffect(() => {
    const eventListener = (event) => {
      const { type } = event.data;

      switch (type) {
        case MESSAGE_EVENTS.STOREFRONT_EDITOR.OVERLAY.CLOSED:
          return onCloseSectionModal();

        default:
          return;
      }
    };

    window.addEventListener('message', eventListener);

    return () => {
      window.removeEventListener('message', eventListener);
    };
  }, [onCloseSectionModal]);

  const { topLabel, bottomLabel } = labels;
  const { topPath, bottomPath } = paths;

  return (
    <FadeIn
      className="section-overlay--add-section-popup-animation"
      duration={200}
      transitionDelay={100}
    >
      <div
        className="section-overlay--add-section-popup"
        onMouseLeave={onClosePopup}
      >
        <AddSectionPopupButton
          path={topPath}
          label={topLabel}
          onClick={onOpenSectionModal}
        />
        <AddSectionPopupButton
          path={bottomPath}
          label={bottomLabel}
          onClick={onOpenSectionModal}
        />
      </div>
    </FadeIn>
  );
}

AddSectionPopup.propTypes = {
  labels: pt.shape({ topLabel: pt.string, bottomLabel: pt.string }),
  paths: pt.shape({ topPath: pt.string, bottomPath: pt.string }),
  onOpenSectionModal: pt.func,
  onCloseSectionModal: pt.func,
  onClose: pt.func,
};

export default React.memo(AddSectionPopup);
