import React from 'react';

import EasyblocksPage from 'components/storefront/editor/easyblocks-page';
import { MESSAGE_EVENTS } from 'components/storefront/editor/utils/post-message';

export default class StorefrontThemeEditorPage extends React.PureComponent {
  state = {
    loaded: false,
  };

  componentDidMount() {
    window.setCanvasData = this.setCanvasData.bind(this);
    window.setThemeGlobals = this.setThemeGlobals.bind(this);
    window.parent.postMessage(
      { type: MESSAGE_EVENTS.STOREFRONT_EDITOR.CANVAS.MOUNTED },
      '*',
    );

    this.removeLoading();
  }

  setCanvasData(canvasData) {
    this.setState({
      ...canvasData,
      loaded: true,
    });
  }

  setThemeGlobals(globals) {
    const themeGlobals = globals || this.state.theme.globals;

    const pageProps = {
      ...(this.state.pageProps || undefined),
      themeGlobals: {
        ...themeGlobals,
      },
    };

    this.setState({
      pageProps,
      themeGlobals: {
        ...themeGlobals,
      },
    });
  }

  removeLoading() {
    const loadingElement = document.getElementById('loading');

    if (loadingElement) {
      loadingElement.remove();
    }
  }

  render() {
    const {
      loaded,
      theme,
      pageProps,
      pageContent,
      layoutProps,
      allSections,
      pageSections,
      layoutSectionGroups,
      sectionGroups,
      themeGlobals,
      pageId,
    } = this.state;

    if (!loaded) {
      return null;
    }

    return (
      <EasyblocksPage
        pageId={pageId}
        pageProps={pageProps}
        pageContent={pageContent}
        layoutProps={layoutProps}
        allSections={allSections}
        pageSections={pageSections}
        layoutSectionGroups={layoutSectionGroups}
        sectionGroups={sectionGroups}
        themeGlobals={themeGlobals}
        theme={theme}
      />
    );
  }
}
