import React, { useContext } from 'react';
import pt from 'prop-types';

import { RootContext } from 'components/storefront/editor/easyblocks-page/components/root';

import {
  getTopGroup,
  isFirstSection,
  isFirstHeaderSection,
  getTopButtonPath,
  getTopButtonPopupLabels,
  getTopButtonPopupPaths,
} from './utils';
import AddSectionButton from './button';

function TopAddSectionButton({
  group,
  index,
  sectionGroups,
  onOpenSectionModal,
  onCloseSectionModal,
}) {
  const rootContext = useContext(RootContext);

  const topGroup = getTopGroup(group, sectionGroups);
  const topGroupSections = topGroup ? rootContext[topGroup] : null;
  const topGroupSectionsLength = topGroupSections?.length || null;

  if (isFirstHeaderSection(group, sectionGroups, index)) {
    return null;
  }

  const popupLabels = getTopButtonPopupLabels(group, sectionGroups);
  const popupPaths = getTopButtonPopupPaths(
    group,
    sectionGroups,
    topGroupSectionsLength,
  );

  if (!popupLabels || !popupPaths) {
    return null;
  }

  return (
    <AddSectionButton
      className="section-overlay--add-section-button-top"
      popup={isFirstSection(index)}
      popupLabels={popupLabels}
      popupPaths={popupPaths}
      path={getTopButtonPath(group, index)}
      onOpenSectionModal={onOpenSectionModal}
      onCloseSectionModal={onCloseSectionModal}
    />
  );
}

TopAddSectionButton.propTypes = {
  group: pt.string,
  index: pt.number,
  sectionGroups: pt.array,
  onOpenSectionModal: pt.func,
  onCloseSectionModal: pt.func,
};

export default React.memo(TopAddSectionButton);
