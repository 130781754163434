import camelCaseAttrMap from 'html-to-react/lib/camel-case-attribute-names';

/** @typedef {import('domhandler').Element} Element */

/**
 * @param {string} name
 * @returns {string}
 */
export function toReactProp(name) {
  name = camelCaseAttrMap[name] || name;

  switch (name) {
    case 'class':
      return 'className';
    case 'for':
      return 'htmlFor';
    case 'value':
      return 'defaultValue';
    default:
      return name;
  }
}

/**
 * @param {string} inlineCss
 * @returns {React.CSSProperties}
 */
export function convertCssToReactStyle(inlineCss) {
  return inlineCss
    .split(';')
    .map((cur) => cur.split(':'))
    .reduce((acc, [key, value]) => {
      if (!key.startsWith('--')) {
        key = key.replace(/-./g, (css) => css[1].toUpperCase());
      }

      acc[key] = value;
      return acc;
    }, {});
}

function toFunction(value) {
  if (typeof value === 'function') {
    return value;
  }

  // eslint-disable-next-line no-new-func
  return Function(value);
}

/**
 * @param {Element} node
 * @param {boolean} [parseStyles]
 */
export function replaceProps(node, parseStyles = true) {
  if (!node?.attribs) {
    return;
  }

  if (typeof node.attribs.style === 'string' && parseStyles) {
    node.attribs.style = convertCssToReactStyle(node.attribs.style);
  }

  // Replace props for react components
  for (const [attr, value] of Object.entries(node.attribs)) {
    const reactAttr = toReactProp(attr);

    if (reactAttr !== attr) {
      delete node.attribs[attr];

      node.attribs[reactAttr] = attr.startsWith('on')
        ? toFunction(value)
        : value;
    }
  }
}

/**
 * Checks whether a node is part of a layout section.
 *
 * This is used to avoid loading assets for layout sections,
 * as their assets are loaded by the `Section` component.
 *
 * @param {Element} node - The DOM node to check.
 * @returns {boolean} Returns `true` if the node is a layout section asset, otherwise `false`.
 */
export function isLayoutSectionAsset(node) {
  if (!node || node.name === 'body') {
    return false;
  }

  if (node.attribs?.id?.startsWith('swell-section-group')) {
    return true;
  }

  return isLayoutSectionAsset(node.parent);
}

/**
 * @param {Element} node
 * @param {[string, unknown][]} attrs
 */
export function applyAttributes(node, attrs) {
  for (const [attr, value] of attrs) {
    node.attribs[attr] = value;
  }
}
