import debounce from 'lodash/debounce';

export const SECTION_GROUP_PREFIX = 'SectionGroup';
export const CONTENT_SECTIONS_PREFIX = 'ContentSections';

const CANVAS_ID = 'shopstory-canvas';
const SWELL_PAGE_ID = 'swell_page';
const SWELL_PAGE_INDEX = 0;
const FORM_UPDATE_DELAY = 500;

export const SWELL_PAGE_PREFIX = `${SWELL_PAGE_ID}.${SWELL_PAGE_INDEX}`;
export const THEME_SETTINGS_ID = 'swell_global';
export const SWELL_DEFAULT = 'swell_default';

function getEditorContext(context = window) {
  return context.editorWindowAPI?.editorContext;
}

function getForm(context) {
  const editorContext = getEditorContext(context);

  return editorContext.form;
}

function onFormUpdated({ values }) {
  const context = getEditorContext();

  context.backend.documents.update({
    entry: values,
  });
}

export function getCanvasElement() {
  return document.getElementById(CANVAS_ID);
}

export function subscribeForm() {
  const form = getForm();

  return form.subscribe(debounce(onFormUpdated, FORM_UPDATE_DELAY), {
    values: true,
  });
}

export function getFormValues() {
  const form = getForm();

  return form.values;
}

export function changeFormValue(path, value, context) {
  const form = getForm(context);

  form.change(path, value);
}

export function getDocument(pageId, easyblocksState) {
  if (!pageId) {
    return null;
  }

  return easyblocksState?.documents?.[pageId];
}

export function getEntryFromDocument(document) {
  return document?.entry;
}

export function getDocumentEntry(pageId, easyblocksState) {
  const document = getDocument(pageId, easyblocksState);

  return getEntryFromDocument(document);
}

export function getPageFromEntry(entry) {
  return entry?.[SWELL_PAGE_ID]?.[SWELL_PAGE_INDEX];
}

export function getPageFromDocument(document) {
  const entry = getEntryFromDocument(document);

  return getPageFromEntry(entry);
}

export function getDocumentPage(pageId, easyblocksState) {
  const entry = getDocumentEntry(pageId, easyblocksState);

  return getPageFromEntry(entry);
}

function getFocusedFieldFromContext(context) {
  // 'focussedField' is an array
  const field = context.focussedField || null;
  const hasFocusedField = field?.length > 0;

  return hasFocusedField ? field[0] : THEME_SETTINGS_ID;
}

export function getFocusedField() {
  const context = getEditorContext();

  if (!context) {
    return THEME_SETTINGS_ID;
  }

  return getFocusedFieldFromContext(context);
}

export function getParentFocusedField() {
  const context = getEditorContext(window.parent);

  if (!context) {
    return THEME_SETTINGS_ID;
  }

  return getFocusedFieldFromContext(context);
}

export function isSectionOrBlockPath(path) {
  if (typeof path !== 'string') {
    return false;
  }

  switch (path) {
    case THEME_SETTINGS_ID:
    case SWELL_PAGE_PREFIX:
    case SWELL_DEFAULT:
      return false;
    default:
      return true;
  }
}

export function parseItemPath(itemPath) {
  if (!isSectionOrBlockPath(itemPath)) {
    return [];
  }

  const path = removeSwellPagePrefix(itemPath);
  const [section, sectionIndex, block, blockIndex] = path.split('.');
  const sectionId = `${section}.${sectionIndex}`;
  let blockId = null;

  if (block) {
    blockId = `${sectionId}.${block}.${blockIndex}`;
  }

  return [sectionId, blockId];
}

export function addSwellPagePrefix(elementId) {
  return `${SWELL_PAGE_PREFIX}.${elementId}`;
}

export function removeSwellPagePrefix(elementId) {
  return (elementId || '').replace(`${SWELL_PAGE_PREFIX}.`, '');
}

function getPageSettingsFields(page) {
  return [
    {
      prop: 'label',
      type: 'swell_short_text',
      label: 'Page title',
      description: 'This controls the title on the top of your browser',
      layout: 'column',
      isLabelHidden: true,
      required: true,
      defaultValue: page.label,
    },
    ...(page.custom
      ? [
          {
            prop: 'slug',
            type: 'swell_short_text',
            label: 'Slug',
            description: 'This is the unique slug for the page',
            layout: 'column',
            isLabelHidden: true,
            required: true,
            defaultValue: page.slug,
            params: {
              slugify: true,
              localized: false,
            },
          },
        ]
      : []),
    {
      prop: 'description',
      type: 'swell_long_text',
      label: 'Description',
      description: 'This controls your page description',
      layout: 'column',
      isLabelHidden: true,
      defaultValue: page.description,
      params: {
        rows: 5,
        placeholder: 'Enter a description...',
      },
    },
    {
      prop: '$locale',
      type: 'swell_locale',
      isLabelHidden: true,
    },
  ];
}

export function setPageSettingsFields(easyblocksConfig, page) {
  const swellPageComponent = easyblocksConfig?.components?.find(
    (component) => component.id === SWELL_PAGE_ID,
  );

  if (!swellPageComponent) {
    return;
  }

  swellPageComponent.schema.push(...getPageSettingsFields(page));
}
