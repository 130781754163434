import Cookies from 'universal-cookie';

const cookies = new Cookies();

export function setCookie(name, value, options = {}) {
  cookies.set(name, JSON.stringify(value), {
    httpOnly: false,
    sameSite: 'lax',
    secure: process.env.NODE_ENV !== 'development',
    ...options,
  });
}

export function getCookie(name) {
  const value = cookies.get(name);
  try {
    return JSON.parse(value);
  } catch (err) {
    return value;
  }
}
