import {
  SWELL_PAGE_PREFIX,
  THEME_SETTINGS_ID,
  SWELL_DEFAULT,
  addSwellPagePrefix,
} from 'components/storefront/editor/utils/easyblocks';

function postMessage(type, payload, context = window) {
  context.postMessage(
    {
      type,
      payload,
      canvas: context !== window,
    },
    '*',
  );
}

function normalizeFocusTarget(target) {
  if (target === SWELL_DEFAULT) {
    return SWELL_DEFAULT;
  } else if (target === THEME_SETTINGS_ID) {
    return THEME_SETTINGS_ID;
  } else if (target.startsWith(SWELL_PAGE_PREFIX)) {
    return target;
  }

  return addSwellPagePrefix(target);
}

export const MESSAGE_EVENTS = Object.freeze({
  EASYBLOCKS: Object.freeze({
    FOCUS: '@easyblocks-editor/focus',
    SELECTION_CHANGED: '@easyblocks-editor/selection-frame-position-changed',
    CANVAS_PAGE_ROUTE: '@easyblocks-editor/canvas-page-route',
    CANVAS_LOADED: '@easyblocks-editor/canvas-loaded',
    ITEM_INSERTED: '@easyblocks-editor/item-inserted',
    ITEM_MOVED: '@easyblocks-editor/item-moved',
    REMOVE_ITEMS: '@easyblocks-editor/remove-items',
    DUPLICATE_ITEMS: '@easyblocks-editor/duplicate-items',
    TOGGLE_ITEM: '@easyblocks-editor/toggle-item',
    CHANGE_RESPONSIVE: '@easyblocks-editor/change-responsive',
    TOGGLE_SETTINGS: '@easyblocks-editor/toggle-settings',
    FORM_CHANGE: '@easyblocks-editor/form-change',
    UNDO: '@easyblocks-editor/undo',
    REDO: '@easyblocks-editor/redo',
    BACKEND: Object.freeze({
      DOCUMENT: Object.freeze({
        UPDATED: '@easyblocks-backend/document-updated',
      }),
    }),
  }),

  STOREFRONT_EDITOR: Object.freeze({
    OVERLAY: Object.freeze({
      ADD_PAGE_ITEM: '@storefront-editor-overlay/add-page-item',
      PAGE_ITEM_ELLIPSIS: '@storefront-editor-overlay/page-item-ellipsis',
      PAGE_ELLIPSIS: '@storefront-editor-overlay/page-ellipsis',
      CLOSED: '@storefront-editor-overlay/closed',
    }),

    ITEM: {
      HOVER: '@storefront-editor-item/hover',
      UNHOVER: '@storefront-editor-item/unhover',
    },

    CANVAS: {
      MOUNTED: '@storefront-editor-canvas/mounted',
      LOADED: '@storefront-editor-canvas/loaded',
      RELOAD: '@storefront-editor-canvas/reload',
    },

    NOTIFY_ERROR: '@storefront-editor/notify-error',
  }),
});

/* Easyblocks events */

export function postEasyblocksItemInserted(name, block, index) {
  postMessage(MESSAGE_EVENTS.EASYBLOCKS.ITEM_INSERTED, { index, name, block });
}

export function postEasyblocksRemoveItems(...paths) {
  postMessage(MESSAGE_EVENTS.EASYBLOCKS.REMOVE_ITEMS, { paths });
}

export function postEasyblocksDuplicateItems(...paths) {
  postMessage(MESSAGE_EVENTS.EASYBLOCKS.DUPLICATE_ITEMS, { paths });
}

export function postEasyblocksToggleItem(path) {
  postMessage(MESSAGE_EVENTS.EASYBLOCKS.TOGGLE_ITEM, { path });
}

export function postEasyblocksItemMoved(fromPath, toPath) {
  postMessage(MESSAGE_EVENTS.EASYBLOCKS.ITEM_MOVED, { fromPath, toPath });
}

export function postEasyblocksFocus(target, context) {
  postMessage(
    MESSAGE_EVENTS.EASYBLOCKS.FOCUS,
    {
      target: normalizeFocusTarget(target),
    },
    context,
  );
}

// context undefined = use default context (window)
export function postEasyblocksCanvasPageRoute(pageId, context) {
  postMessage(MESSAGE_EVENTS.EASYBLOCKS.CANVAS_PAGE_ROUTE, { pageId }, context);
}

export function postEasyblocksChangeResponsive(viewport) {
  postMessage(MESSAGE_EVENTS.EASYBLOCKS.CHANGE_RESPONSIVE, {
    device: viewport,
  });
}

export function postEasyblocksUndo() {
  postMessage(MESSAGE_EVENTS.EASYBLOCKS.UNDO);
}

export function postEasyblocksRedo() {
  postMessage(MESSAGE_EVENTS.EASYBLOCKS.REDO);
}

export function postEasyblocksFormChange(key, value, context) {
  postMessage(MESSAGE_EVENTS.EASYBLOCKS.FORM_CHANGE, { key, value }, context);
}

/* Easyblocks backend events */

export function postEasyblocksBackendDocumentUpdated() {
  postMessage(MESSAGE_EVENTS.EASYBLOCKS.BACKEND.DOCUMENT.UPDATED);
}

/* Storefront events */

export function postStorefrontOverlayAddPageItem(
  domRect,
  path,
  context,
  overlayProps = {},
) {
  postMessage(
    MESSAGE_EVENTS.STOREFRONT_EDITOR.OVERLAY.ADD_PAGE_ITEM,
    {
      domRect,
      path,
      overlayProps,
    },
    context,
  );
}

export function postStorefrontOverlayPageItemEllipsis(
  domRect,
  path,
  overlayProps = {},
) {
  postMessage(MESSAGE_EVENTS.STOREFRONT_EDITOR.OVERLAY.PAGE_ITEM_ELLIPSIS, {
    domRect,
    path,
    overlayProps,
  });
}

export function postStorefrontOverlayPageEllipsis(
  domRect,
  page,
  overlayProps = {},
) {
  postMessage(MESSAGE_EVENTS.STOREFRONT_EDITOR.OVERLAY.PAGE_ELLIPSIS, {
    domRect,
    page,
    overlayProps,
  });
}

export function postStorefrontOverlayClosed(context) {
  postMessage(MESSAGE_EVENTS.STOREFRONT_EDITOR.OVERLAY.CLOSED, null, context);
}

export function postStorefrontItemHover(path, context) {
  if (!path) {
    return;
  }

  postMessage(MESSAGE_EVENTS.STOREFRONT_EDITOR.ITEM.HOVER, { path }, context);
}

export function postStorefrontItemUnhover(path, context) {
  if (!path) {
    return;
  }

  postMessage(MESSAGE_EVENTS.STOREFRONT_EDITOR.ITEM.UNHOVER, { path }, context);
}

export function postStorefrontCanvasLoaded(context) {
  postMessage(MESSAGE_EVENTS.STOREFRONT_EDITOR.CANVAS.LOADED, null, context);
}

export function postStorefrontCanvasReload(context) {
  postMessage(MESSAGE_EVENTS.STOREFRONT_EDITOR.CANVAS.RELOAD, null, context);
}

export function postStorefrontNotifyError(message, context) {
  postMessage(
    MESSAGE_EVENTS.STOREFRONT_EDITOR.NOTIFY_ERROR,
    { message },
    context,
  );
}
