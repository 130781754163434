import React, { useContext } from 'react';
import { RootContext } from 'components/storefront/editor/easyblocks-page/components/root';
import pt from 'prop-types';
import AddBlockButton from './button';
import {
  parsePath,
  canAddBlock,
  getTopButtonPath,
  getBottomButtonPath,
} from './utils';

import './buttons.scss';

function AddBlockButtons({
  path,
  blockConfigs,
  onOpenBlockModal,
  onCloseBlockModal,
}) {
  const rootContext = useContext(RootContext);

  if (!rootContext) {
    return null;
  }

  const [sectionGroup, sectionIndex, blockGroup, blockIndex] = parsePath(path);
  const section = rootContext[sectionGroup]?.[sectionIndex];

  if (!blockGroup || !canAddBlock(section, blockConfigs)) {
    return null;
  }

  const group = `${sectionGroup}.${sectionIndex}.${blockGroup}`;

  return (
    <div className="block-overlay--add-block-buttons">
      <AddBlockButton
        className="block-overlay--add-block-button-top"
        path={getTopButtonPath(group, blockIndex)}
        onOpenBlockModal={onOpenBlockModal}
        onCloseBlockModal={onCloseBlockModal}
      />
      <AddBlockButton
        className="block-overlay--add-block-button-bottom"
        path={getBottomButtonPath(group, blockIndex)}
        onOpenBlockModal={onOpenBlockModal}
        onCloseBlockModal={onCloseBlockModal}
      />
    </div>
  );
}

AddBlockButtons.propTypes = {
  path: pt.string,
  blockConfigs: pt.object,
  onOpenBlockModal: pt.func,
  onCloseBlockModal: pt.func,
};

export default React.memo(AddBlockButtons);
