import { isInlineEditableType } from 'components/storefront/editor/easyblocks-page/components/inline-editable';

import { getAttributeValue } from './utils';

export const INLINE_EDITABLE_ATTRIBUTE = 'data-swell-inline-editable';

/**
 * @param {object} blockConfig
 * @param {object[]} inlineEditableConfigs
 */
function processInlineEditable(blockConfig, inlineEditableConfigs) {
  return {
    selector: `[${INLINE_EDITABLE_ATTRIBUTE}]`,
    processNode: (node) => {
      const fieldId = getAttributeValue(node, INLINE_EDITABLE_ATTRIBUTE);
      const field = blockConfig?.fields?.find((field) => field.id === fieldId);

      if (!field || !isInlineEditableType(field.type)) {
        return;
      }

      inlineEditableConfigs.push({
        container: node,
        content: node.innerHTML,
        type: field.type,
        path: blockConfig.path,
        fieldId,
      });

      node.innerHTML = '';
    },
  };
}

export default processInlineEditable;
