import { Swell, SwellTheme } from '@swell/apps-sdk';
import uuidv4 from 'uuid/v4';

import geoSettings from 'utils/geo';
import { getCookie, setCookie } from 'utils/cookie';
import { getClientUrl } from 'utils/container';

import { getMockResource, getMockSingleton } from './page-data';

const { CLIENT_URL, VAULT_URL_DEV, BASE_URI } = process.env;

export const STOREFRONT_EDITOR_CANVAS_PATH = '/storefront-editor-canvas';

function getSwellHeaders(client, storefront) {
  const {
    apps: { results: apps },
  } = client;
  const storefrontApp = apps.find(
    (app) => Boolean(app.public_key) && app.app_id === storefront.app_id,
  );

  return {
    'store-id': client.id,
    'storefront-id': storefront?.id,
    'admin-url': CLIENT_URL?.replace('CLIENT_ID', client.id),
    'vault-url': VAULT_URL_DEV,
    'public-key': storefrontApp.public_key,
    'request-id': uuidv4(),
    // 'Swell-Environment-Id': storefront.environment_id,
    // 'Swell-App-Id': storefront.app_id,
    // 'Swell-App-Version': storefront.version,
    // 'Swell-Theme-Id': storefront?.theme_id,
    // 'Swell-Theme-Version': storefront?.theme_version,
    // 'Swell-Theme-Config-Version': `preview-${
    //   storefront.theme.config_version || 1
    // }`,
    // 'Swell-Deployment-Mode': 'editor',
    // 'Swell-Cache-Modified': storefront?.date_published,
  };
}

export function getThemeConfigsMap(themeConfigs) {
  const themeConfigsMap = new Map();

  for (const config of themeConfigs?.results ?? []) {
    themeConfigsMap.set(config.file_path, config);
  }

  return themeConfigsMap;
}

/**
 * @param {SwellTheme} swellTheme
 * @param {object} params
 * @returns {SwellTheme}
 */
function configTheme(swellTheme, { themeConfigs }) {
  swellTheme.themeConfigs = getThemeConfigsMap(themeConfigs);
  swellTheme.geoSettings = geoSettings;

  return swellTheme;
}

/**
 * @returns {Swell}
 */
export function getSwell(
  client,
  storefront,
  config,
  shopifyCompatibilityConfig,
) {
  const headers = getSwellHeaders(client, storefront);

  return new Swell({
    url: new URL(window.location.href),
    config,
    shopifyCompatibilityConfig,
    headers,
    swellHeaders: headers,
    isEditor: true,
    getCookie,
    setCookie,
  });
}

function getResources(resourcesConfig) {
  if (!resourcesConfig) {
    return { singletons: {}, records: {} };
  }

  const { singletons, records } = resourcesConfig;

  const loadResources = (resourceList) =>
    Object.fromEntries(
      Object.entries(resourceList).map(([key, resource]) => [
        key,
        getMockResource(resource),
      ]),
    );

  const loadSingletons = (singletonList) =>
    Object.fromEntries(
      Object.entries(singletonList).map(([key, singleton]) => [
        key,
        getMockSingleton(singleton),
      ]),
    );

  return {
    singletons: loadSingletons(singletons || {}),
    records: loadResources(records || {}),
  };
}

/**
 * @param {Swell} swellClient
 * @param {object} data
 * @param {object} resourcesConfig
 * @param {object[]} forms
 * @returns {SwellTheme}
 */
export function getTheme(swellClient, data, resourcesConfig, forms) {
  const swellTheme = new SwellTheme(swellClient, {
    resources: getResources(resourcesConfig),
    forms:
      forms?.length > 0
        ? forms.map((item) => {
            return {
              ...item,
              handler: () => {},
            };
          })
        : [],
  });

  return configTheme(swellTheme, data);
}

export function getCanvasUrl(client, storefront) {
  if (!storefront) {
    return null;
  }

  const clientUrl = getClientUrl(client);

  if (!clientUrl) {
    return null;
  }

  return `${clientUrl}${BASE_URI}/${client.environment_id || ''}/storefronts/${
    storefront.id
  }${STOREFRONT_EDITOR_CANVAS_PATH}`;
}
