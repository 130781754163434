import { setCookie, getCookie } from 'utils/cookie';
import { getPageIdFromRoute } from 'utils/storefront';
import {
  postEasyblocksCanvasPageRoute,
  postStorefrontCanvasReload,
  postStorefrontNotifyError,
} from 'components/storefront/editor/utils/post-message';

/** @typedef {import('@swell/apps-sdk').SwellTheme} SwellTheme */

const CLIENT_DOMAIN_REGEX = new RegExp(process.env.CLIENT_DOMAIN_PATTERN);

export function loadScript(src, content, type, assetLoaders) {
  if (!src && !content) {
    return;
  }

  let existingScript;

  if (src) {
    existingScript = document.querySelector(`script[src="${src}"]`);
  } else {
    existingScript = Array.from(document.querySelectorAll('script')).find(
      (script) => script.innerHTML.trim() === content.trim(),
    );
  }

  if (existingScript) {
    return;
  }

  const loader = () => {
    const script = document.createElement('script');

    if (type) {
      script.setAttribute('type', type);
    }

    if (src) {
      script.src = src;
      script.defer = true;
      script.async = false;
    } else {
      script.innerHTML = content;
    }

    document.head.appendChild(script);

    if (!src) {
      return Promise.resolve();
    }

    return new Promise((resolve, reject) => {
      script.onload = resolve;
      script.onerror = (event) => {
        reject(new Error(event.toString()));
      };
    });
  };

  assetLoaders.push(loader);
}

export function loadStyle(hash, content, assetLoaders) {
  if (!hash && !content) {
    return;
  }

  let existingStyle;

  if (hash) {
    existingStyle = document.head.querySelector(`style[data-hash="${hash}"]`);
  } else if (content) {
    existingStyle = Array.from(document.querySelectorAll('style')).find(
      (style) => style.innerHTML.trim() === content.trim(),
    );
  }

  if (!existingStyle) {
    const loader = () => {
      const style = document.createElement('style');

      if (hash) {
        style.setAttribute('data-hash', hash);
      }

      style.innerHTML = content;

      document.head.appendChild(style);

      return Promise.resolve();
    };

    assetLoaders.push(loader);

    return;
  } else if (hash) {
    existingStyle.innerHTML = content;
  }
}

export function loadStylesheet(href, assetLoaders) {
  if (!href) {
    return;
  }

  const existingLink = document.querySelector(`link[href="${href}"]`);

  if (existingLink) {
    return;
  }

  assetLoaders.push(() => {
    const link = document.createElement('link');

    link.rel = 'stylesheet';
    link.href = href;

    document.head.appendChild(link);

    return new Promise((resolve, reject) => {
      link.onload = resolve;
      link.onerror = (event) => {
        reject(new Error(event.toString()));
      };
    });
  });
}

const LINK_REGEX = /^https?:\/\//i;

/**
 * @param {SwellTheme} theme
 * @param {string} href
 * @returns {[string, unknown][]}
 */
export function handleLink(theme, href) {
  if (LINK_REGEX.test(href)) {
    return [['style', 'pointer-events: none; cursor: default;']];
  }

  const isAnchor = href?.startsWith('#');

  if (!href || isAnchor) {
    return [];
  }

  const pageId = getPageIdFromRoute(href, theme.props.pages);
  // threat links without pageId as form actions
  const formAction = href !== '#' && !pageId;

  return [
    ['href', '#'],
    [
      'onclick',
      function onClickLink() {
        if (formAction) {
          // form action
          formActionHandler(theme, '', href, 'get');
        } else {
          // page redirect link
          postEasyblocksCanvasPageRoute(pageId, window.parent);
        }
      },
    ],
  ];
}

/**
 * @param {SwellTheme} theme
 * @param {string} [form]
 * @param {string} [type]
 * @returns {[string, unknown][]}
 */
export function handleButton(theme, form, type) {
  if (!form) {
    return [];
  }

  const action = `/${form}`;
  const isFormSubmitButton =
    type === 'submit' &&
    form &&
    theme.shopifyCompatibility?.isScriptFormActionUrl(action);

  if (!isFormSubmitButton) {
    return [];
  }

  return [
    [
      'onclick',
      function onClickButton(event) {
        event.preventDefault();

        const body = new FormData();

        formActionHandler(theme, form, action, 'post', body);
      },
    ],
  ];
}

/**
 * @param {SwellTheme} theme
 * @param {string} [action]
 * @returns {[string, unknown][]}
 */
export function handleForm(theme, action) {
  if (!action) {
    return [];
  }

  const themeForm = (theme?.forms || []).find(
    (themeForm) => themeForm.url === action,
  );

  if (!themeForm) {
    return [];
  }

  return [
    [
      'onsubmit',
      function onSubmitForm(event) {
        event.preventDefault();
        const eventForm = event.target;

        // do not duplicate script actions
        if (theme.shopifyCompatibility?.isScriptFormActionUrl(action)) {
          return;
        }

        const body = new FormData(eventForm);

        formActionHandler(theme, themeForm.id, action, 'post', body, eventForm);
      },
    ],
  ];
}

/**
 * @param {SwellTheme} theme
 * @param {string} formId
 * @param {Response} res
 * @returns {Promise<Response | undefined>}
 */
export async function handleFormActionResponse(theme, formId, res) {
  try {
    const result = await res.clone().json();
    const { redirect, swellData } = result;
    let shouldReloadPage = false;

    theme.clearFormData();

    if (swellData) {
      // update cookies
      console.log('handleFormActionResponse', swellData);

      setCookie('swell-data', swellData || {});

      const session = swellData['swell-session'] || '';
      const locale = swellData['swell-locale'];
      const currency = swellData['swell-currency'];

      setCookie('swell-session', session);

      if (locale && locale !== getCookie('swell-locale')) {
        setCookie('swell-locale', locale);
        shouldReloadPage = true;
      }

      if (currency && currency !== getCookie('swell-currency')) {
        setCookie('swell-currency', currency);
        shouldReloadPage = true;
      }

      // use actual formData
      const swellFormData = swellData['swell-form-data'];

      if (swellFormData) {
        try {
          const data = JSON.parse(swellFormData);
          const formData = data?.[formId];
          theme.setFormData(formId, formData);
        } catch {
          // none
        }
      }
    }

    if (shouldReloadPage) {
      return postStorefrontCanvasReload(window.parent);
    }

    let pageId = '';
    if (redirect) {
      if (theme.shopifyCompatibility?.isCheckoutForm(formId)) {
        // redirect to checkout editor. Redirect to checkout right now
        window.parent.location.href = redirect;
        return res;
      }

      // redirect in editor
      pageId = getPageIdFromRoute(redirect, theme.props.pages) || '';
    }

    // reload current page
    if (!pageId || theme.pageId === pageId) {
      // the same page: refresh page with updated globals
      window.setThemeGlobals();
      if (theme.shopifyCompatibility?.needRedirectToPageStart(formId)) {
        window.location.href = '#';
        return res;
      }
    } else {
      // show page
      postEasyblocksCanvasPageRoute(pageId, window.parent);
    }

    return res;
  } catch (err) {
    postStorefrontNotifyError('Unable to perform form action', window.parent);
  }
}

/**
 * @param {SwellTheme} theme
 * @param {string} formId
 * @param {string} action
 * @param {string} method
 * @param {BodyInit} [body]
 * @param {HTMLFormElement} [eventForm]
 */
export async function formActionHandler(
  theme,
  formId,
  action,
  method,
  body,
  eventForm,
) {
  try {
    const res = await fetch(action, {
      method,
      body: method === 'post' || method === 'put' ? body : undefined,
    });

    // we always clear forms in Proxima
    if (eventForm) {
      eventForm.reset();
    }

    return handleFormActionResponse(theme, formId, res);
  } catch (err) {
    postStorefrontNotifyError('Unable to perform form action', window.parent);
  }
}

/**
 * @param {RequestInfo | URL} input
 * @param {RequestInit} [options]
 * @returns {[string, RequestInit]}
 */
function parseFetchArgs(input, options) {
  if (input instanceof Request) {
    return [input.url, input];
  }

  return [input.toString(), options || {}];
}

/**
 * @param {SwellTheme} theme
 * @returns {void}
 */
export function initGlobalHandlers(theme) {
  if (window.globalHandlersAssigned) {
    return;
  }

  // redefine global fetch function to handle shopify script actions
  const handler = window.fetch;
  window.fetch = function fetch(...args) {
    const [origUrl, options] = parseFetchArgs(...args);
    const origin = theme?.swell?.storefront_url || window.location.origin;
    const url = new URL(origUrl, origin);

    if (url.origin !== origin && !CLIENT_DOMAIN_REGEX.test(url.hostname)) {
      return handler(url, options);
    }

    // add editor headers
    options.headers = new Headers(options.headers);

    options.headers.set(
      'Swell-Data',
      JSON.stringify(getCookie('swell-data') || {}),
    );

    options.headers.set('X-Session', getCookie('swell-session'));
    options.headers.set('Swell-Is-Editor', true);

    return handler(url, options).then((res) => {
      if (theme.shopifyCompatibility?.isScriptFormActionUrl(origUrl)) {
        // apply response
        return handleFormActionResponse(theme, '', res);
      }

      return res;
    });
  };

  // assign global form submit handler to handle this.submit();
  function formSubmitHandler() {
    const action = this.getAttribute('action');
    const method = this.getAttribute('method');
    const body = new FormData(this);
    formActionHandler(theme, action, action, method, body);
  }
  HTMLFormElement.prototype.submit = formSubmitHandler;

  window.globalHandlersAssigned = true;
}
